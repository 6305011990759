import * as React from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { InputTextField } from '../FormFields/RenderInputTextField.tsx';
import { useDispatch, useSelector } from "react-redux";
import { CheckboxField } from '../FormFields/RenderCheckboxField.tsx';
import { createTransporter, updateTransporter } from '../../data/transporters.js';
import {
    hideModal,
    hideChildModal,
  } from "../../data/modal.tsx";

import {
    Button,
    Grid,
    Typography,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';
import Drivers from './Drivers.tsx';


const TransporterForm = ({object, tenant, callback, showDriver = true}) => {
    const dispatch = useDispatch();
    
    const selectedData = useSelector((state) => ({
        modal: state.modal
    }));

    const { handleSubmit, control, formState, register } = useForm({
        defaultValues: object,
    });
    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler = (data) => {
        let formData = data;
    
        if (tenant !== undefined) {
            formData = {
                ...data,
                tenant: tenant['@id'],
            }
        } 

        if (object) {
            dispatch(updateTransporter(formData));
        } else {
            dispatch(createTransporter(formData, callback));
        }
    };

    const handleCloseForm = () => {
        if (selectedData.modal.childModal) {
          dispatch(hideChildModal());
        } else {
          dispatch(hideModal());
        }
      };
    
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={4}>
                            <CheckboxField
                                name="active"
                                label="is Active"
                                control={control}
                                defaultValue={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputTextField
                                name='name'
                                label='Name'
                                control={ control }
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                { showDriver ? (
                    <Grid item xs={12}>
                        <Typography variant="h6" className="form-fields-title">
                            Drivers
                        </Typography>
                        <Grid item xs={12} className="form-fields-container">
                            <Grid item xs={12} sm={12}>
                                <Drivers
                                    name={`drivers`}
                                    label="Drivers"
                                    control={control}
                                    register={register}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : ''}
                <Grid item xs={12} className='button-wrapper'>
                <Button 
                    onClick={() => {handleCloseForm()}}
                    disabled={ isSubmitting }
                    variant='contained'
                >
                    <CloseIcon fontSize="inherit" />&nbsp;Close
                </Button>
                    <Button 
                        type='submit'
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <SaveIcon fontSize="inherit" />&nbsp;Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default TransporterForm;