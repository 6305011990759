import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout.tsx";
import { showModal } from "../../../data/modal.tsx";
import TenantFilter from "../../partial/TenantFilter.tsx";
import { getSites } from "../../../data/site.js";
import ActiveFilter from "../../partial/ActiveFilter.tsx";

import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";

import {
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import {
  Edit as EditIcon,
} from "@mui/icons-material";
import { selectFilteredSites } from "../../../data/selectors/filterSelector.js";

const SiteManage = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const filteredSites = useSelector(selectFilteredSites);


  const selectedData = useSelector((state) => ({
    sites: state.sites,
    filter: state.filter,
  }));

  const { filter, sites } = selectedData;

  useEffect(() => {
    dispatch(getSites());
  }, [dispatch, filter]);

  const renderActions = (params: GridRenderCellParams<any, number>) => {
    let site = filteredSites.find(
      (site) => site["@id"] === params.value
    );

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: "Edit site",
              description: "Edit a site",
              form: "site",
              formProps: { site: site },
            })
          );
        }}
      >
        <EditIcon size="small" />
      </IconButton>
    );
  };

  useEffect(() => {
    setRows(formatDataGridData(filteredSites));
  }, [filteredSites]);

  const formatDataGridData: any = (sites): GridRowsProp[] => {
    let formattedSites: GridRowsProp[] = [];

    sites.map((site) => {
      formattedSites.push({
        id: site["@id"],
        name: site.name,
        active: site.active,
        address: site.address ? `${ site.address.number ?? '' } ${ site.address.street ?? '' }, ${ site.address.postalCode ?? '' } ${ site.address.city ?? '' }, ${ site.address.country ?? '' }` : ''
      });
    });

    return formattedSites;
  };

  let columns: GridColDef[] = [
    {
      field: "active",
      headerName: "Active",
      minWidth: 150,
      type: "boolean",
    },
    { field: "name", headerName: "Name", minWidth: 450 },
    {
      field: "address",
      headerName: "Address",
      minWidth: 250,
    },
    {
      field: "id",
      headerName: "",
      minWidth: 50,
      renderCell: renderActions,
      editable: false,
      flex: 1,
      align: "right",
    },
  ];

  return (
    <Layout>
      <Grid container>
      <Grid item xs={12} sm={2} padding={1}>
        <Grid item component={ Paper }  sx={{ height: '100%' }}>
            <ActiveFilter/>
            <TenantFilter/>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} padding={1}>
        <Grid item  sx={{ height: 'auto' }}>
            <Grid
              className="settings-page-header"
              item
              xs={12}
              sm={12}
              padding={1}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  Sites
                </Typography>
              </Grid>
              <Grid item>
                { filter.active ? (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      dispatch(
                        showModal({
                          title: `Add a site ${ filter.tenant ? `to ${ filter.tenant.name }`: ''}`,
                          description: "Creates a new site",
                          form: "site",
                          formProps: { 
                            tenant: filter.tenant
                          },
                        })
                      );
                    }}
                  >
                    Add Site
                  </Button>
                ) : ''}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} component={Paper}>
              <Grid item sx={{ height: "80vh" }}>
                <DataGrid
                  hideFooter={true}
                  columns={columns}
                  sx={{ marginBottom: "25px" }}
                  rows={rows}
                  getRowHeight={() => 'auto'}
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  loading={sites.loading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default SiteManage;
