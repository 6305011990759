import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout.tsx";
import { showModal } from "../../../data/modal.tsx";
import TenantFilter from "../../partial/TenantFilter.tsx";
import { getTrainings } from "../../../data/training.js";
import { selectFilteredTrainings, selectFilteredUsers } from "../../../data/selectors/filterSelector.js";
import { fileIconHandler, formatDateToString, hasFile } from "../../Helpers.tsx";

import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";

import {
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import {
  Edit as EditIcon,
  InsertDriveFileSharp as InsertDriveFileSharpIcon,
} from "@mui/icons-material";
import { getUsers } from "../../../data/users.js";
import { addActiveFilterValue } from "../../../data/filter.tsx";

const TrainingManage = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const filteredTrainings = useSelector(selectFilteredTrainings);
  const filteredUsers = useSelector(selectFilteredUsers);

  const selectedData = useSelector((state) => ({
    trainings: state.trainings,
    filter: state.filter,
  }));

  const { filter, trainings } = selectedData;

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getTrainings());
    dispatch(addActiveFilterValue(true));
  }, [dispatch]);

  const renderActions = (params: GridRenderCellParams<any, number>) => {
    let training = filteredTrainings.find(
      (training) => training["@id"] === params.value
    );

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: "Edit training",
              description: "Edit a training",
              form: "training",
              formProps: { 
                training: training,
                users: filteredUsers,
              },
            })
          );
        }}
      >
        <EditIcon size="small" />
      </IconButton>
    );
  };

  const renderFileGroup = (params: GridRenderCellParams<any, number>) => {
    let training = filteredTrainings.find(
      (training) => training === params.value
    );

    if (!training) {
      return <></>
    }

    const trainingCertificates = training.fileGroup;

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: "Training Certificates",
              description: "Training certificates",
              form: "filegroup",
              formProps: { 
                object: training,
              },
            })
          );
        }}
      >
        <InsertDriveFileSharpIcon 
          size="small" 
          color={ fileIconHandler(trainingCertificates) }
          opacity={ hasFile(trainingCertificates) ? 1 : 0.4 }
        />
      </IconButton>
    );
  }

  useEffect(() => {
    setRows(formatDataGridData(filteredTrainings));
  }, [filteredTrainings]);

  const formatDataGridData: any = (trainings): GridRowsProp[] => {
    let formattedSites: GridRowsProp[] = [];

    trainings.map((training) => {
      formattedSites.push({
        id: training["@id"],
        title: training.title,
        certificate: training.certificateReceived,
        certificates: training,
        documentDate: formatDateToString(training.documentDate),
      });
    });

    return formattedSites;
  };

  let columns: GridColDef[] = [
    { field: "title", headerName: "Title", minWidth: 200 },
    { field: "certificate", headerName: "Certificate Received", minWidth: 200 },
    { field: "documentDate", headerName: "Date", minWidth: 200 },
    {
      field: "certificates",
      headerName: "Certificates",
      minWidth: 50,
      renderCell: renderFileGroup,
      editable: false,
      align: "center",
    },
    {
      field: "id",
      headerName: "",
      minWidth: 50,
      renderCell: renderActions,
      editable: false,
      flex: 1,
      align: "right",
    },
  ];

  return (
    <Layout>
      <Grid container>
      <Grid item xs={12} sm={2} padding={1}>
        <Grid item component={ Paper }  sx={{ height: '100%' }}>
            <TenantFilter/>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} padding={1}>
        <Grid item  sx={{ height: 'auto' }}>
            <Grid
              className="settings-page-header"
              item
              xs={12}
              sm={12}
              padding={1}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  Trainings
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    dispatch(
                      showModal({
                        title: `Add a training ${ filter.tenant ? `to ${ filter.tenant.name }`: ''}`,
                        description: "Creates a new training",
                        form: "training",
                        formProps: { 
                          tenant: filter.tenant
                        },
                      })
                    );
                  }}
                >
                  Add Training
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} component={Paper}>
              <Grid item sx={{ height: "80vh" }}>
                <DataGrid
                  hideFooter={true}
                  columns={columns}
                  sx={{ marginBottom: "25px" }}
                  rows={rows}
                  getRowHeight={() => 'auto'}
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  loading={trainings.loading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default TrainingManage;
