import "../../styles/form.css";
import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxField } from "../FormFields/RenderCheckboxField.tsx";
import { InputTextField } from "../FormFields/RenderInputTextField.tsx";
import { InputTextareaField } from "../FormFields/RenderInputTextareaField.tsx";
import { SelectField } from "../FormFields/RenderSelectField.tsx";
import { NumberField } from "../FormFields/RenderNumberField.tsx";
import { SelectMultipleField } from "../FormFields/RenderSelectMultipleField.tsx";
import { GetClassificationOptions, GetPackagingOptions } from "./SelectOptionsFormatter.tsx";

import {
  hideModal,
  showChildModal,
  showModal,
  hideChildModal,
} from "../../data/modal.tsx";

import {
  createProduct,
  updateProduct,
  removeProduct,
} from "../../data/product";

import { Button, Divider, Grid, Typography } from "@mui/material";

import {
  Close as CloseIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { SingleFileField } from "../FormFields/RenderSingleFileField.tsx";

const ProductForm = ({ product, classifications, packagings, tenant, callback}) => {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
    modal: state.modal
  }));

  const { handleSubmit, control, formState, register, setValue, } = useForm({
    defaultValues: product,
  });

  const { isSubmitting } = formState;

  const onSubmit: SubmitHandler = (data) => {
    let formData = data;

    if (tenant !== undefined) {
        formData = {
          ...data,
          tenant: tenant['@id'],
        }
    } 

    if (product) {
      dispatch(updateProduct(formData));
    } else {
      dispatch(createProduct(formData, callback));
    }
  };

  const transportCategoryOptions = () => {
    return [
      {
        value: "0",
        label: "Category 0",
      },
      {
        value: "1",
        label: "Category 1",
      },
      {
        value: "2",
        label: "Category 2",
      },
      {
        value: "3",
        label: "Category 3",
      },
      {
        value: "4",
        label: "Category 4",
      },
    ];
  };

  const handleCloseForm = () => {
    if (selectedData.modal.childModal) {
      dispatch(hideChildModal());
    } else {
      dispatch(hideModal());
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container maxWidth="80vh">
        <Grid item xs={12} sm={12}>
          <Grid item xs={12}>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <CheckboxField
                  name="active"
                  label="is Active"
                  control={control}
                  defaultValue={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <SelectField
                  name="transportType"
                  label="Transport type"
                  control={control}
                  options={[
                    {
                      value: "incoming",
                      label: "Incoming",
                    },
                    {
                      value: "outgoing",
                      label: "Outgoing",
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              Name
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="nameNl"
                  label="Name NL"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="nameEn"
                  label="Name EN"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="nameDe"
                  label="Name DE"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="nameFr"
                  label="Name FR"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              Description
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="descriptionNl"
                  label="Description NL"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="descriptionEn"
                  label="Description EN"
                  control={control}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="descriptionDe"
                  label="Description DE"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="descriptionFr"
                  label="Description FR"
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <InputTextareaField
                  name="fullDescriptionNl"
                  label="Full Description NL"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextareaField
                  name="fullDescriptionEn"
                  label="Full Description EN"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextareaField
                  name="fullDescriptionDe"
                  label="Full Description DE"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextareaField
                  name="fullDescriptionFr"
                  label="Full Description FR"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              Classification
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={12}>
                <SelectMultipleField
                  name="classifications"
                  label="Classifications"
                  control={control}
                  options={GetClassificationOptions(classifications)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              Packaging
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={6}>
                <SelectField
                  name="packaging"
                  label="Packaging"
                  control={control}
                  options={GetPackagingOptions(packagings)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField name="unit" label="Unit" control={control} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              Other information
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <SelectField
                  name="category"
                  label="Transport category"
                  control={control}
                  options={transportCategoryOptions()}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <NumberField
                  name="unNr"
                  label="UN (Prefixed with UN####)"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="adrLabel"
                  label="ADR/IMDG Label"
                  control={control}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="tunnelRestrictionCode"
                  label="Tunnel Restriction Code"
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <InputTextField name="ge" label="GE" control={control} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              SDS File
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={6}>
                <SingleFileField
                    register={register}
                    setValue={setValue}
                    control={control}
                    name="sdsFile"
                    existingFile={product?.sdsFile}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="button-wrapper">
          <Grid container spacing={1}>
            <Grid item>
              <Button
                onClick={() => {handleCloseForm()}}
                disabled={isSubmitting}
                variant="contained"
              >
                <CloseIcon fontSize="inherit" />
                &nbsp;Close
              </Button>
            </Grid>
            {product && (
              <Grid item>
                <Button
                  onClick={() => {
                    dispatch(removeProduct(product));
                  }}
                  disabled={isSubmitting}
                  variant="contained"
                  color="error"
                >
                  <DeleteIcon fontSize="inherit" />
                  &nbsp;Delete
                </Button>
              </Grid>
            )}
          </Grid>
          <Button type="submit" disabled={isSubmitting} variant="contained">
            <SaveIcon fontSize="inherit" />
            &nbsp;Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProductForm;
