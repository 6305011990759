import React, { useEffect, useState } from 'react';
import  { Link } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../data/user";
import Logo from './Logo.tsx';
import Modal from "./Modal.tsx";
import { showModal } from "../data/modal.tsx";
import { addCurrentUser } from '../data/currentUser.tsx';

import { 
  Avatar,
  AppBar as MuiAppBar, 
  Divider,
  Toolbar, 
  Container, 
  IconButton, 
  Typography,
  Grid,
  Paper, 
  Menu,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Box, 
  CssBaseline,
  Stack,
  Tooltip,
  FormControl,
} from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  Menu as MenuIcon,
  CallReceivedSharp as CallReceivedSharpIcon,
  CallMadeSharp as CallMadeSharpIcon,
  ReportProblemSharp as ReportProblemSharpIcon,
  QueryStatsSharp as QueryStatsSharpIcon,
  Tune as TuneIcon,
  ApartmentSharp as ApartmentSharpIcon,
  LogoutSharp as LogoutSharpIcon,
  InventorySharp as InventorySharpIcon,
  Group as GroupIcon,
  Domain as DomainIcon,
  Place as PlaceIcon,
  Inventory2Sharp as Inventory2SharpIcon,
  List as ListIcon,
  WorkspacePremium as WorkspacePremiumIcon,
} from '@mui/icons-material';

const defaultTheme = createTheme();

export default function Layout({ children }:any) {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState('');
  const [superAdmin, setSuperAdmin] = useState(false);

  const selectedData = useSelector((state) => ({
    user: state.user.data,
  }));

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  const {
    user
  } = selectedData;

  useEffect(() => {
    if (user) {
      dispatch(addCurrentUser(user));
      
      setUserName(`${user?.lastName ?? ''} ${user?.firstName ?? ''}`)

      if (user.roles && user.roles.includes("ROLE_SUPER_ADMIN")) {
        setSuperAdmin(true)
      }
    }

    if (!localStorage.getItem("token")) {
      window.location.href = '/'
    }
  }, [user]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userMenu, setUserMenu] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
      setUserMenu(null);
  };

  const handleUserMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };


  const stringToColor = (string: string) => {
    let hash = 0;
    let i;
  
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
  
    return color;
  }
    
  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const [language, setLanguage] = React.useState('EN');

  const handleLanguageChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as string);
  };

  const logout = () => {
    localStorage.removeItem('token')
    window.location.href = '/'
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <MuiAppBar>
          <Toolbar>
            <Grid 
              sx={{ 
                display: 'flex',
                flexGrow: 1,
                alignContent: 'center',
                alignItems: 'center',
                gap: '5px'
              }}>
              <Logo/>
              <IconButton
                  id="menu-button"
                  aria-controls={open ? 'navigation-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{ color: '#FFFFFF' }}
              >
                  <MenuIcon />
              </IconButton>
              <Menu
                  id="navigation-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'menu-button',
                  }}
                  sx={{ paddingTop: 0, paddingBottom: 0 }}
                >
                <Paper sx={{ width: 320, height: '100%', boxShadow: 'none' }}>
                  <MenuList>
                    <Link to='/transport-document/incoming'>
                      <MenuItem>
                        <ListItemIcon>
                          <CallReceivedSharpIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Incoming Transports</ListItemText>
                      </MenuItem>
                    </Link>
                    <Link to='/transport-document/outgoing'>
                      <MenuItem>
                        <ListItemIcon>
                          <CallMadeSharpIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Outgoing Transports</ListItemText>
                      </MenuItem>
                    </Link>
                    <Link to='/incidents'>
                      <MenuItem>
                        <ListItemIcon>
                          <ReportProblemSharpIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Incidents</ListItemText>
                      </MenuItem>
                    </Link>
                    <Link to='/reports/transport/incoming'>
                      <MenuItem>
                        <ListItemIcon>
                          <QueryStatsSharpIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Reports</ListItemText>
                      </MenuItem>
                    </Link>
                    { superAdmin ? (
                      <>
                          <Divider/>
                          <Link to='/settings/admin/product'>
                            <MenuItem>
                              <ListItemIcon>
                                <InventorySharpIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Products</ListItemText>
                            </MenuItem>
                          </Link>
                          <Link to='/settings/admin/packaging'>
                            <MenuItem>
                              <ListItemIcon>
                                <Inventory2SharpIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Packaging</ListItemText>
                            </MenuItem>
                          </Link>
                          <Link to='/settings/admin/user'>
                            <MenuItem>
                              <ListItemIcon>
                                <GroupIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Users</ListItemText>
                            </MenuItem>
                          </Link>
                          <Link to='/settings/admin/training'>
                            <MenuItem>
                              <ListItemIcon>
                                <WorkspacePremiumIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Trainings</ListItemText>
                            </MenuItem>
                          </Link>
                          <Link to='/settings/admin/site'>
                            <MenuItem>
                              <ListItemIcon>
                                <PlaceIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Sites</ListItemText>
                            </MenuItem>
                          </Link>
                          <Link to='/settings/admin/tenant'>
                            <MenuItem>
                              <ListItemIcon>
                                <DomainIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Tenants</ListItemText>
                            </MenuItem>
                          </Link>
                      </>
                    ) : (
                      <>
                      <Link to='/user-list'>
                        <MenuItem>
                          <ListItemIcon>
                            <GroupIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Users</ListItemText>
                        </MenuItem>
                      </Link>
                      <Link to='/product-list'>
                        <MenuItem>
                          <ListItemIcon>
                            <ListIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Products</ListItemText>
                        </MenuItem>
                      </Link>
                      </>
                    )}
                    <Divider/>
                    <Link to='/settings/contact'>
                      <MenuItem>
                        <ListItemIcon>
                          <TuneIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Settings</ListItemText>
                      </MenuItem>
                    </Link>
                  </MenuList>
                </Paper>
              </Menu>
            </Grid>
            <Grid item sx={{ display:'flex', gap:'15px' }}>
            { userName && userName.length > 0 ? (
              <>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleUserMenuClick}>
                <Avatar {...stringAvatar(userName)} sx={{ width: 30, height: 30, fontSize: '1rem' }} />
                <Typography>{userName}</Typography>
              </Stack>
              <Menu
                id="simple-menu"
                anchorEl={userMenu}
                keepMounted
                open={Boolean(userMenu)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    dispatch(
                      showModal({
                        title: "Tenant",
                        description: "Tenant edit",
                        form: "tenant",
                        formProps: { 
                          tenant: user.tenant,
                        },
                      })
                    );
                  }}
                >
                  <ListItemIcon>
                    <ApartmentSharpIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Tenant</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => logout() }>
                  <ListItemIcon>
                    <LogoutSharpIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </MenuItem>
              </Menu>
              </>
            ) : ''}
            <FormControl>
                  <Select
                    value={'EN'}
                    label="Language"
                    onChange={handleLanguageChange}
                    variant='standard'
                  >
                    <MenuItem value={'EN'}>EN</MenuItem>
                    <Tooltip title="Not available in demo.">
                      <MenuItem value={'NL'}>NL</MenuItem>
                    </Tooltip>
                    <Tooltip title="Not available in demo.">
                      <MenuItem value={'DE'}>DE</MenuItem>
                    </Tooltip>
                    <Tooltip title="Not available in demo.">
                      <MenuItem value={'FR'}>FR</MenuItem>
                    </Tooltip>
                  </Select>
                </FormControl>
            </Grid>
          </Toolbar>
        </MuiAppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 4, mb: 4, padding: '0 !important',  maxWidth: 'none !important' }}> 
            <Modal isSuperAdmin={ superAdmin} />
            <main>{children}</main>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}