import * as React from 'react';
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import { InputTextField } from '../FormFields/RenderInputTextField.tsx';
import { NumberField } from '../FormFields/RenderNumberField.tsx';
import { hideModal } from "../../data/modal.tsx";
import { useDispatch } from "react-redux";
import { createPackaging, updatePackaging } from '../../data/packaging.js';

import {
    Button,
    Grid,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';


const PackagingForm = ({packaging, tenant}) => {
    const dispatch = useDispatch();
    
    const { handleSubmit, control, formState } = useForm({
        defaultValues: packaging,
    });
    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler = (data) => {
        let formData = data;
    
        if (tenant !== undefined) {
            formData = {
              ...data,
              tenant: tenant['@id'],
            }
        } 
    
        if (packaging) {
          dispatch(updatePackaging(formData));
        } else {
          dispatch(createPackaging(formData));
        }
      };
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={3}>
                            <InputTextField
                                name='nameEn'
                                label='Name EN'
                                control={ control }
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputTextField
                                name='nameNl'
                                label='Name NL'
                                control={ control }
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputTextField
                                name='nameFr'
                                label='Name FR'
                                control={ control }
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputTextField
                                name='nameDe'
                                label='Name DE'
                                control={ control }
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <NumberField
                                name='multiplier'
                                label='Packaging multiplier'
                                control={ control }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='button-wrapper'>
                <Button 
                    onClick={() => {dispatch(hideModal())}}
                    disabled={ isSubmitting }
                    variant='contained'
                >
                    <CloseIcon fontSize="inherit" />&nbsp;Close
                </Button>
                    <Button 
                        type='submit'
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <SaveIcon fontSize="inherit" />&nbsp;Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default PackagingForm;