import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { createFile } from "../../data/files.js";
import { showChildModal } from "../../data/modal.tsx";
import FileUploadProgressBar from "../partial/FileUploadProgress.tsx";

import {
  Box,
  Grid,
  FormControl,
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import {
  FileOpenSharp as FileOpenSharpIcon,
  UploadFileSharp as UploadFileSharpIcon,
  FileCopySharp as FileCopySharpIcon,
  InsertDriveFileSharp as InsertDriveFileSharpIcon,
} from "@mui/icons-material";

export const SingleFileField: any = ({
  name,
  control,
  register,
  setValue,
  existingFile,
}) => {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
    files: state.files,
  }));

  const { files } = selectedData;

  const dropzone = useDropzone({
    onDrop: (files: File[]) => files && fileDropHandler(files),
    multiple: false,
  });

  const uploadHandler = async (file) => {
    return dispatch(createFile(file));
  };


  const fileDropHandler = async (files) => {
    const uploadPromises = files.map((file) => uploadHandler(file));

    Promise.all(uploadPromises).then((values: Array<any>) =>
      setValue(name, values && values.length > 0 ? values[0] : undefined)
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={9} className="form-fields-container">
          <FormControl size={"small"} sx={{ width: "100%" }}>
            <Controller
              control={control}
              render={() => (
                <Grid
                  item
                  {...dropzone.getRootProps({ className: "dropzone" })}
                  sx={{
                    minHeight: "20vh",
                    height: "100%",
                    border: "3px dotted #DDDDDD",
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 2,
                  }}
                >
                  <Box
                    component="input"
                    {...dropzone.getInputProps({
                      ...register(name),
                    })}
                  />
                  <Box sx={{ width: '100%' }}>
                    <Typography>Drop files here to {existingFile ? 'replace the uploaded' : 'upload'} files</Typography>
                  </Box>
                  <FileUploadProgressBar 
                    loading={ files.loading } 
                    loaded={files.progress.loaded} 
                    total={ dropzone.acceptedFiles.length } 
                    singleFile={true}
                    error={ files.error } 
                  />
                </Grid>
              )}
              name={name}
              defaultValue={null}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} className="form-fields-container">
          <MuiList>
            {dropzone.acceptedFiles && dropzone.acceptedFiles.length > 0 ? (
              <ListItem disabled>
                <ListItemIcon>
                  <UploadFileSharpIcon />
                </ListItemIcon>
                <ListItemText primary="Dropped file" />
              </ListItem>
            ) : (
              ""
            )}
            {dropzone.acceptedFiles &&
              dropzone.acceptedFiles.map((file, index) => (
                <ListItem disablePadding key={`uploaded-file-${index}`}>
                  <ListItemButton>
                    <ListItemIcon>
                      <FileOpenSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary={`${file.name}`} />
                  </ListItemButton>
                </ListItem>
              ))}

              { existingFile ? (
                <>
                <ListItem disabled>
                  <ListItemIcon>
                    <FileCopySharpIcon />
                  </ListItemIcon>
                  <ListItemText primary="Uploaded file" />
                </ListItem>
                <ListItem 
                  disablePadding 
                  data-existing-file-id={existingFile['@id']}
                  >
                    <ListItemButton 
                        onClick={() =>
                          dispatch(
                            showChildModal({
                              title: `File View`,
                              description: existingFile.description ?? '',
                              form: "fileView",
                              formProps: {
                                file: existingFile,
                                showCloseButton: true,
                              },
                            })
                          )
                        }
                    >
                        <ListItemIcon>
                        <InsertDriveFileSharpIcon />
                        </ListItemIcon>
                        <ListItemText primary={`${ existingFile.description }`} />
                    </ListItemButton>
                </ListItem>
                </>
              ) : ''}
          </MuiList>
        </Grid>
      </Grid>
    </>
  );
};
