import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout.tsx";
import { getUsers } from "../../data/users.js";
import { getSites } from "../../data/site.js";
import RenderUserList from "../partial/Users.tsx";
import { addActiveFilterValue } from "../../data/filter.tsx";
import { selectFilteredSites, selectFilteredTrainings, selectFilteredUsers } from "../../data/selectors/filterSelector.js";
import { getTrainings } from "../../data/training.js";

import {
  GridRowsProp,
} from "@mui/x-data-grid";

import {
  Grid,
  Typography,
} from "@mui/material";

const UserList = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const selectedData = useSelector((state) => ({
    users: state.users,
  }));

  const filteredUsers = useSelector(selectFilteredUsers);
  const filteredSites = useSelector(selectFilteredSites);
  const filteredTrainings = useSelector(selectFilteredTrainings);

  const { users } = selectedData;

  useEffect(() => {
    dispatch(addActiveFilterValue(true));
    dispatch(getUsers());
    dispatch(getSites());
    dispatch(getTrainings());
  }, [dispatch]);

  useEffect(() => {
    setRows(formatDataGridData(filteredUsers));
  }, [filteredUsers]);

  const formatDataGridData: any = (users): GridRowsProp[] => {
    let formattedUsers: GridRowsProp[] = [];

    users.map((user) => {
      formattedUsers.push({
        id: user["@id"],
        name: `${ user.lastName ?? '' } ${ user.firstName ?? '' }`,
        email: user.email ?? '',
        active: user.isActive,
        sites: user.userSites,
        trainings: user.userTrainings,
        certificates: user,
      });
    });

    return formattedUsers;
  };

  return (
    <Layout>
      <Grid container>
      <Grid item xs={12} sm={12} padding={1}>
        <Grid item  sx={{ height: 'auto' }}>
            <Grid
              className="settings-page-header"
              item
              xs={12}
              sm={12}
              padding={1}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  Users
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <RenderUserList
              users={filteredUsers}
              sites={filteredSites}
              trainings={filteredTrainings}
              rows={rows}
              editable={false}
              isLoading={users.loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default UserList;
