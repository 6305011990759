import "../../styles/form.css";
import React, { useState, useEffect } from "react";
import { hideModal } from "../../data/modal.tsx";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { InputTextField } from "../FormFields/RenderInputTextField.tsx";
import { SignatureField } from "../FormFields/RenderSignatureField.tsx";
import { DRIVER_ADN_CERTIFICATE, getChecklistAnswers, getChecklistQuestions, TRANSPORT_MEDIUM_COLLI, TRANSPORT_TYPE_INCOMING, TransportChecklist } from "./TransportChecklist.tsx";
import GeneratePDF, { DOCUMENT_ORIENTATION_PORTRAIT } from "../partial/GeneratePdf.tsx";
import { capitalizeFirstLetter, DEFAULT_LANGUAGES, findObjectById, isValidCertificate } from "../Helpers.tsx";
import { selectFilteredDrivers } from "../../data/selectors/filterSelector.js";

import {
  Stack,
  Button,
  Alert,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";

import {
  updateIncomingTransport,
} from "../../data/incomingTransport.js";

import {
  updateOutgoingTransport,
} from "../../data/outgoingTransport.js";

import {
  Close as CloseIcon,
  Save as SaveIcon,
} from "@mui/icons-material";

const TransportChecklistForm = ({
  transport,
  type = TRANSPORT_TYPE_INCOMING,
}) => {
    const dispatch = useDispatch();
    const selectedData = useSelector((state) => ({
      user: state.currentUser.data,
    }));

    const filteredDrivers = useSelector(selectFilteredDrivers);
    const {
      user
    } = selectedData;
    const currentUser = transport.user ?? user
    const tenant =  currentUser.tenant ?? undefined;
    const logo = tenant && tenant.logo ? tenant.logo?.contentUrl : undefined;
    const documentLanguages = transport && transport.documentLanguages ? transport.documentLanguages : DEFAULT_LANGUAGES;
    const defaultCheckList = TransportChecklist(
      type,
      transport && transport.transportMedium ? transport.transportMedium : TRANSPORT_MEDIUM_COLLI,
    );
    
    const [checklistAnswers, setChecklistAnswers] = useState(transport && transport.checkList ? transport.checkList : getChecklistAnswers(defaultCheckList))

    const { handleSubmit, formState, control, setValue, getValues } = useForm({
      defaultValues: transport,
    });

    const { isSubmitting } = formState;
    
    const onSubmit: SubmitHandler = (data) => {
      if (!transport) {
        return;
      }
      
      let transportData = data;


      if (checklistAnswers.length > 0) {
        transportData = {
            ...transportData,
            checkList: checklistAnswers,
        }
      }

      let incoming = transport["@type"].includes("incoming")
      dispatch(incoming ? updateIncomingTransport(transportData) : updateOutgoingTransport(transportData));
    };

    useEffect(() => {
      if (user && !transport.checkListIssuer) {
        setValue('checkListIssuer', `${user?.lastName ?? ''} ${user?.firstName ?? ''}`)
      }

      if (defaultCheckList.length !== checklistAnswers.length) {
        setChecklistAnswers(getChecklistAnswers(defaultCheckList))
      }
    }, [user]);

    const checkListHandler = (checklistNumber: number, answer: boolean) => {
      let updatedChecklist = checklistAnswers.map((checklistAnswer, index) => {
          if (checklistNumber === index) {
            return answer;
          }

          return checklistAnswer;
      })

      setChecklistAnswers(updatedChecklist);
    }

    const questionStringHandler = (index) => {
      if (!defaultCheckList[index]) {
        return '';
      }

      return getChecklistQuestions(defaultCheckList[index].question, documentLanguages)
    }

    const driverADNCertitifcateChecker = () => {
      const driver = findObjectById(filteredDrivers, transport.driver)
    
      if (!driver.certificateExpirationDate || !transport.documentDate) {
        return false;
      }

      return isValidCertificate(driver.certificateExpirationDate, transport.documentDate);
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container  className='pdf-document-content' flexDirection='column' flexWrap='nowrap' padding='10px'>
          <Grid item>
              <TableContainer>
                  <Table size="small">
                      <TableHead>
                          <TableRow>
                              <TableCell width={200} sx={{ backgroundColor: 'transparent !important' }}>
                                  { logo ? (
                                      <img src={`${ logo }`} height={ 80 } alt="TenantLogo"/>
                                  ) : ''}
                              </TableCell>
                              <TableCell align='right' sx={{ backgroundColor: 'transparent !important' }}>
                                  <Typography fontWeight={700} fontSize='20px'>Checklist ADR</Typography>
                                  <Typography fontSize='inherit'>{ transport.number ?? '' }</Typography>
                                  <Typography fontSize='inherit'>{ type ? capitalizeFirstLetter(type) : '' }</Typography>
                              </TableCell>
                          </TableRow>
                      </TableHead>
                  </Table>
              </TableContainer>
          </Grid>
          <Grid item sx={{ display: 'flex', gap: '2px', marginTop: '5px' }}>
            <Stack spacing={0.5} width='100%'>
              { checklistAnswers.map((checklistAnswer, index) => (
                <Alert 
                  severity={`${ checklistAnswer ? 'success' : 'error'}`} 
                  action={
                    <Stack spacing={1} direction="row">
                      <Button 
                        color={`${ checklistAnswer ? 'success' : 'inherit'}`} 
                        size="small" 
                        variant='contained'
                        onClick={() => checkListHandler(index, true) }
                      >
                        OK
                      </Button>
                      <Button 
                        color={`${ !checklistAnswer ? 'error' : 'inherit'}`} 
                        size="small"
                        variant='contained'
                        onClick={() => checkListHandler(index, false) }
                      >
                        NOK
                      </Button>
                    </Stack>
                  }
                >
                  { questionStringHandler(index) }
                </Alert>
              ))}
              { transport.driver ? (
                <Alert 
                  severity={`${ driverADNCertitifcateChecker() ? 'success' : 'error'}`} 
                  action={
                    <Stack spacing={1} direction="row">
                      <Button 
                        color={`${ driverADNCertitifcateChecker() ? 'success' : 'inherit'}`} 
                        size="small" 
                        variant='contained'
                      >
                        OK
                      </Button>
                      <Button 
                        color={`${ !driverADNCertitifcateChecker() ? 'error' : 'inherit'}`} 
                        size="small"
                        variant='contained'
                      >
                        NOK
                      </Button>
                    </Stack>
                  }
                >
                  { getChecklistQuestions(DRIVER_ADN_CERTIFICATE, documentLanguages) }
                </Alert>
              ) : ''}
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '15px' }}>
            <Grid item xs={6}>
                <Grid item data-html2canvas-ignore="true">
                  <InputTextField
                      name='checkListIssuer'
                      label='Issuer'
                      control={ control }
                  />
                </Grid>
                <SignatureField
                    name = 'checkListIssuerSignature'
                    setValue = { setValue }
                    signatureLabel = {transport.checkListIssuer ?? ''}
                    existingSignature = { transport.checkListIssuerSignature  }
                />
            </Grid>
            <Grid item xs={6}>
              <Grid item data-html2canvas-ignore="true">
                <InputTextField
                    name='checkListSignatory'
                    label='Signatory'
                    control={ control }
                />
              </Grid>
              <SignatureField
                  name = 'checkListSignatorySignature'
                  setValue = { setValue }
                  signatureLabel = {transport.checkListSignatory ?? ''}
                  existingSignature = { transport.checkListSignatorySignature  }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="button-wrapper">
            <Grid item>
              <Button
                onClick={() => {
                  dispatch(hideModal());
                }}
                disabled={isSubmitting}
                variant="contained"
              >
                <CloseIcon fontSize="inherit" />
                &nbsp;Close
              </Button>
            </Grid>
            <Grid item sx={{ display: "flex", gap: "5px" }}>
              <GeneratePDF orientation={ DOCUMENT_ORIENTATION_PORTRAIT } />
              <Button type="submit" disabled={isSubmitting} variant="contained">
                <SaveIcon fontSize="inherit" />
                &nbsp;Save
              </Button>
            </Grid>
        </Grid>
      </form>
    );
};

export default TransportChecklistForm;
