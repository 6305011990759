import * as React from 'react';
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import { InputTextField } from '../FormFields/RenderInputTextField.tsx';
import { hideModal } from "../../data/modal.tsx";
import { useDispatch } from "react-redux";
import { CheckboxField } from '../FormFields/RenderCheckboxField.tsx';
import { createSite, updateSite } from '../../data/site.js';

import {
    Button,
    Divider,
    Grid,
    Typography,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

const SiteForm = ({site, tenant}) => {
    const dispatch = useDispatch();

    const { handleSubmit, control, formState } = useForm({
        defaultValues: site,
    });
    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler = (data) => {
        let formData = data;
    
        if (tenant !== undefined) {
            formData = {
                ...data,
                tenant: tenant['@id'],
            }
        } 

        if (site) {
            dispatch(updateSite(formData));
        } else {
            dispatch(createSite(formData));
        }
    };
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={4}>
                            <CheckboxField
                            name="active"
                            label="is Active"
                            control={control}
                            defaultValue={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputTextField
                                name='name'
                                label='Name'
                                control={ control }
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
                    <Divider orientation="horizontal" />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography variant='h6' className='form-fields-title'>
                        Address
                    </Typography>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={2}>
                            <InputTextField
                                name='address.number'
                                label='Number'
                                control={ control }
                            />
                        </Grid> 
                        <Grid item xs={12} sm={4}>
                            <InputTextField
                                name='address.street'
                                label='Street'
                                control={ control }
                            />
                        </Grid> 
                        <Grid item xs={12} sm={2}>
                            <InputTextField
                                name='address.postalCode'
                                label='Postal Code'
                                control={ control }
                            />
                        </Grid> 
                        <Grid item xs={12} sm={2}>
                            <InputTextField
                                name='address.city'
                                label='City'
                                control={ control }
                            />
                        </Grid> 
                        <Grid item xs={12} sm={2}>
                            <InputTextField
                                name='address.country'
                                label='Country'
                                control={ control }
                            />
                        </Grid> 
                    </Grid>
                </Grid>

                <Grid item xs={12} className='button-wrapper'>
                <Button 
                    onClick={() => {dispatch(hideModal())}}
                    disabled={ isSubmitting }
                    variant='contained'
                >
                    <CloseIcon fontSize="inherit" />&nbsp;Close
                </Button>
                    <Button 
                        type='submit'
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <SaveIcon fontSize="inherit" />&nbsp;Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default SiteForm;