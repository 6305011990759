import axios from "axios";
import { hideChildModal, hideModal } from "../data/modal.tsx";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_DRIVERS = "FETCH_DRIVERS";
const FETCH_DRIVERS_SUCCESS = "FETCH_DRIVERS_SUCCESS";
const FETCH_DRIVERS_ERROR = "FETCH_DRIVERS_ERROR";
const PUT_DRIVER = "PUT_DRIVER";
const POST_DRIVER = "POST_DRIVER";
const DELETE_DRIVER = "DELETE_DRIVER";
const FETCH_NEXT_DRIVERS = "FETCH_NEXT_DRIVERS";
const FETCH_NEXT_DRIVERS_SUCCESS = "FETCH_NEXT_DRIVERS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getDrivers = () => async (dispatch) => {
  dispatch(loadDrivers());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/drivers?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No drivers found"));
  } else {
    dispatch(
      setDrivers(
        response.data["hydra:member"],
        parseInt(response.data["hydra:totalItems"], 10)
      )
    );
    if (
      parseInt(response.data["hydra:totalItems"], 10) >
      response.data["hydra:member"].length
    ) {
      dispatch(getNextDrivers());
    }
  }
};

export const getNextTransporters = () => async (dispatch, getState) => {
  const {
    drivers: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextTransporters(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/drivers?page=${pageToLoad}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextTransporters(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextTransporters());
  }
};

export const updateDriver =
  (driver) => async (dispatch, getState) => {
    dispatch(putDriver());
    const {
      drivers: {
        data,
        progress: { total },
      },
      modal: childModal,
    } = getState();

    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/drivers/${driver[
        "@id"
      ].replace("/api/drivers/", "")}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: driver,
    });

    if (response.status === 200) {
      const resultData = data.map((driver) => {
        if (driver["@id"] === response.data["@id"]) {
          return response.data;
        }

        return driver;
      });

      dispatch(setDrivers(resultData, total));

      if (childModal) {
        dispatch(hideChildModal());
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong updating");
    }
  };

export const createDriver = (driver, callback, args) => async (dispatch, getState) => {
    dispatch(postDriver());
    const {
      drivers: {
        data,
        progress: { total },
      },
      modal: childModal,
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/drivers`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: driver,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];

      dispatch(setDrivers(resultData, total));
      
      if (childModal) {
        dispatch(hideChildModal());
        callback(args, response.data["@id"]);
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong deleting");
    }
  };

export const removeDriver = (driver) => async (dispatch, getState) => {
  dispatch(putDriver());
  const {
    drivers: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/drivers/${driver[
      "@id"
    ].replace("/api/drivers/", "")}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== driver["@id"]);

    dispatch(setDrivers(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadDrivers = () => ({ type: FETCH_DRIVERS });

export const putDriver = () => ({ type: PUT_DRIVER });

export const postDriver = () => ({ type: PUT_DRIVER });

export const deleteDriver = () => ({ type: DELETE_DRIVER });

export const setDrivers = (drivers, total) => ({
  type: FETCH_DRIVERS_SUCCESS,
  payload: { drivers, total },
});

export const loadNextDrivers = (page) => ({
  type: FETCH_NEXT_PDRIVER,
  payload: page,
});
export const setNextDrivers = (drivers) => ({
  type: FETCH_NEXT_DRIVERS_SUCCESS,
  payload: drivers,
});

export const setError = (msg) => ({
  type: FETCH_DRIVERS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const driverReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DRIVERS:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_DRIVERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.drivers,
        progress: {
          ...state.progress,
          loaded: payload.drivers.length,
          pages: Math.ceil(payload.total / payload.drivers.length),
          total: payload.total,
        },
      };

    case PUT_DRIVER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_DRIVER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_DRIVER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_DRIVERS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_DRIVERS:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_DRIVERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    default:
      return state;
  }
};

export default driverReducer;
