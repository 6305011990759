import "../../styles/form.css";
import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { InputTextField } from "../FormFields/RenderInputTextField.tsx";
import { SelectField } from "../FormFields/RenderSelectField.tsx";
import { DateField } from "../FormFields/RenderDateField.tsx";
import Products from "./Products.tsx";
import calculateSubTotalForRow, {calculateTotalQuantity, 
  findProductForId,
} from "../FormFields/TotalCalculation.tsx";
import RenderClassification from "../partial/ProductClassification.tsx";
import { findObjectById, findObjectsByIds } from "../Helpers.tsx";
import { SelectMultipleField } from "../FormFields/RenderSelectMultipleField.tsx";
import {
  GetLanguageOptions,
  GetTransportMediumOptions,
} from "./SelectOptionsFormatter.tsx";
import RenderTransportModality from "../partial/TransportModality.tsx";
import {
  selectFilteredDrivers,
  selectFilteredIncomingTransports,
  selectFilteredOutgoingTransports,
  selectFilteredTransporters,
} from "../../data/selectors/filterSelector.js";
import { addTransporterFilterValue, removeTransporterFilterValue } from "../../data/filter.tsx";
import { hideModal, showChildModal } from "../../data/modal.tsx";

import { Button, Divider, Grid, Typography, IconButton } from "@mui/material";
import { SelectCrudField } from "../FormFields/RenderSelectCrudField.tsx";

import {
  updateIncomingTransport,
  removeIncomingTransport,
  createIncomingTransport
} from "../../data/incomingTransports.js";

import {
  updateOutgoingTransport,
  removeOutgoingTransport,
  createOutgoingTransport
} from "../../data/outgoingTransports.js";

import {
  Add as AddIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  ArrowForwardSharp as ArrowForwardSharpIcon,
} from "@mui/icons-material";
import Containers from "./Containers.tsx";

const TransportForm = ({
  labels,
  products,
  transport,
  sites,
  incoming = true,
  tenant,
  transportFilter,
}) => {
  const dispatch = useDispatch();
  const [calculatedTotalADR, setCalculatedTotalADR] = useState(0);
  const [transportProductIds, setTransportProductIds] = useState([]);
  const [transportMedium, setTransportMedium] = useState(
    transport && transport.transportMedium
      ? transport.transportMedium
      : undefined
  );
  const transportProducts = incoming
    ? "incomingTransportProducts"
    : "outgoingTransportProducts";
  const filteredSites =
    transportFilter && transportFilter.sites.length > 0
      ? findObjectsByIds(sites, transportFilter.sites)
      : undefined;

  const filteredOutgoingTransports = useSelector(selectFilteredOutgoingTransports)
  const filteredIncomingTransports = useSelector(selectFilteredIncomingTransports)
  const filteredTransports = incoming ? filteredIncomingTransports : filteredOutgoingTransports;
  const filteredTransporters = useSelector(selectFilteredTransporters);
  const filteredDrivers = useSelector(selectFilteredDrivers);
  const selectedData = useSelector((state) => ({
    contacts: state.contacts,
    filter: state.filter,
  }));
  let newestTransportNumber = filteredTransports.slice(0)[0] ? filteredTransports.slice(0)[0].number : null;
  let autoIncrementedNumber = "";

  if (newestTransportNumber) {
    let matchedLastNumbers = newestTransportNumber.match(/\d+$/)
      ? newestTransportNumber.match(/\d+$/)[0]
      : "";

    if (matchedLastNumbers) {
      autoIncrementedNumber =
        newestTransportNumber.replace(/\d+$/, "") +
        String(parseInt(matchedLastNumbers) + 1).padStart(
          matchedLastNumbers.length,
          "0"
        );
    }
  }

  const { handleSubmit, control, formState, register, setValue, watch } =
    useForm({
      defaultValues: transport
        ? transport
        : {
            "adr-full": "limited",
            number: autoIncrementedNumber,
            status: "draft",
            documentDate: new Date(),
            site:
              filteredSites && filteredSites.length > 0
                ? filteredSites[0]["@id"]
                : undefined,
          },
    });
  const { isSubmitting } = formState;

  const onSubmit: SubmitHandler = (data) => {
    let formData = data;

    formData["adr-full"] = calculatedTotalADR >= 1000 ? "full" : "limited";

    if (
      formData.containers &&
      formData.containers.length > 0 &&
      formData.transportMedium !== "container"
    ) {
      formData = {
        ...formData,
        containers: [],
      };
    }

    if (tenant !== undefined) {
      formData = {
        ...data,
        tenant: tenant,
      };
    }

    if (selectedData.filter.tenant !== undefined) {
      formData = {
        ...data,
        tenant: selectedData.filter.tenant,
      };
    }

    if (transport) {
      dispatch(incoming ? updateIncomingTransport(formData) : updateOutgoingTransport(formData));
    } else {
      dispatch(incoming ? createIncomingTransport(formData) : createOutgoingTransport(formData));
    }
  };

  const getProductIds = (transportProducts) => {
    const productIds = [];

    transportProducts.map((transportProduct) => {
      const product =
        transportProduct.product && transportProduct.product["@id"]
          ? transportProduct.product["@id"]
          : transportProduct.product;

      if (product !== undefined) {
        productIds.push(product);
      }
    });

    setTransportProductIds(productIds);
  };

  useEffect(() => {
    if (
      transport &&
      (transport.incomingTransportProducts || transport.outgoingTransportProducts)
    ) {
      const valueProducts = incoming
        ? transport.incomingTransportProducts
        : transport.outgoingTransportProducts;
  
      valueProducts.map((transportProduct, index) =>
        calculateTotalQuantity(transportProduct, index, setValue, products)
      );
  
      setCalculatedTotalADR(
        valueProducts.reduce(
          (acc, value) => acc + calculateSubTotalForRow(value, products),
          0
        )
      );
    }

    if (transport && transport.transporter) {
      dispatch(addTransporterFilterValue(transport.transporter))
    }
  }, []);

  useEffect(() => {
    const subscription = watch((value: any, { name, type }) => {
      getProductIds(value[transportProducts]);
      
      if (name && name.includes('transporter')) {
        setValue('driver', null)
        
        if (value.transporter !== null) {
          dispatch(addTransporterFilterValue(findObjectById(filteredTransporters, value.transporter)))
        }
      }

      if (name && name === "transportMedium") {
        setTransportMedium(value.transportMedium);
      }

      if (name && name.includes("TransportProducts")) {
        const valueProducts = incoming
          ? value.incomingTransportProducts
          : value.outgoingTransportProducts;

        if (name.includes("product") || name.includes("packagingQuantity")) {
          valueProducts.map((transportProduct, index) =>
            calculateTotalQuantity(transportProduct, index, setValue, products)
          );
        }

        setCalculatedTotalADR(
          valueProducts.reduce(
            (acc, value) => acc + calculateSubTotalForRow(value, products),
            0
          )
        );
      }
    });

    if (transport && transport[transportProducts]) {
      getProductIds(transport[transportProducts]);
    }

    return () => subscription.unsubscribe();
  }, [watch]);

  const statusOptions = () => {
    return [
      {
        value: "draft",
        label: "Draft",
      },
      {
        value: "new",
        label: "New",
      },
      {
        value: "ongoing",
        label: "Ongoing",
      },
      {
        value: "done",
        label: "Done",
      },
    ];
  };

  const labelOptions = () => {
    const labelOptions = [];

    if (!labels || labels.length === 0) {
      return labelOptions;
    }

    labels.map((label: Label) => {
      labelOptions.push({
        value: label["@id"],
        label: label.name,
        color: label.color,
      });
    });

    return labelOptions;
  };

  const contactOptions = () => {
    const contactOptions = [];

    if (
      !selectedData.contacts.data ||
      selectedData.contacts.data.length === 0
    ) {
      return contactOptions;
    }

    selectedData.contacts.data.map((contact: Contact) => {
      contactOptions.push({
        value: contact["@id"],
        label: `${contact.name}${
          contact.phoneNumber ? `- ${contact.phoneNumber}` : ""
        }`,
      });
    });
    return contactOptions;
  };

  const siteOptions = () => {
    const siteOptions = [];

    if (!sites || sites.length === 0) {
      return siteOptions;
    }

    sites.map((site: any) => {
      siteOptions.push({
        value: site["@id"],
        label: site.name,
      });
    });

    return siteOptions;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12} sm={12} sx={{ display: "flex", gap: "5px" }}>
          <Grid item xs={12} sm={12} sx={{ minWidth: "65vh" }}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" className="form-fields-title">
                Modality
              </Typography>
              <Grid
                item
                xs={12}
                className="form-fields-container"
                sx={{ justifyContent: "space-around" }}
              >
                <RenderTransportModality />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" className="form-fields-title">
                Transport Information
              </Typography>
              <Grid item xs={12} className="form-fields-container">
                <Grid item xs={12} sm={4}>
                  <InputTextField
                    name="number"
                    label="Number"
                    control={control}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectField
                    name="status"
                    label="Status"
                    control={control}
                    options={statusOptions()}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="form-field-datetime">
                  <DateField
                    name="documentDate"
                    label="Date"
                    control={control}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className="form-fields-container">
                <Grid item xs={12} sm={4}>
                  <SelectField
                    name="site"
                    label="Site"
                    control={control}
                    options={siteOptions()}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <SelectMultipleField
                    name="labels"
                    label="Labels"
                    control={control}
                    options={labelOptions()}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
              <Divider orientation="horizontal" />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <Grid item xs={12} sm={12}>
                <RenderClassification
                  products={findObjectsByIds(products, transportProductIds)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    gap: "2px",
                    marginBottom: "2px",
                  }}
                >
                  <Button
                    size="small"
                    fullWidth
                    variant="contained"
                    disabled={calculatedTotalADR >= 1000 ? true : false}
                  >
                    Limited
                  </Button>
                  <Button
                    size="small"
                    fullWidth
                    variant="contained"
                    disabled={calculatedTotalADR >= 1000 ? false : true}
                  >
                    Full ADR
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    height: "40px",
                    alignItems: "center",
                    gap: "5px",
                    justifyContent: "center",
                    color: "#FFFFFF",
                    backgroundColor: "#2186EB",
                    borderRadius: "5px",
                  }}
                >
                  <Typography fontWeight={700}>{calculatedTotalADR}</Typography>
                  <Typography size="inherit">ADR points</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
              <Divider orientation="horizontal" />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" className="form-fields-title">
                Locations
              </Typography>
              <Grid item xs={12} className="form-fields-container">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sm={5}
                >
                  <Grid item xs={10}>
                    <SelectField
                      name="fromContact"
                      label="From"
                      control={control}
                      options={contactOptions()}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        dispatch(
                          showChildModal({
                            title: `Add contact`,
                            description: "Quick add contact",
                            form: "contact",
                            formProps: {
                              callback: setValue,
                              args: "fromContact",
                            },
                          })
                        )
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ArrowForwardSharpIcon size="small" color="primary" />
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sm={5}
                >
                  <Grid item xs={10}>
                    <SelectField
                      name="toContact"
                      label="To"
                      control={control}
                      options={contactOptions()}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        dispatch(
                          showChildModal({
                            title: `Add contact`,
                            description: "Quick add contact",
                            form: "contact",
                            formProps: {
                              callback: setValue,
                              args: "toContact",
                            },
                          })
                        )
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
              <Divider orientation="horizontal" />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" className="form-fields-title">
                Transporter
              </Typography>
              <Grid item xs={12} className="form-fields-container">
                <Grid item xs={12} sm={2}>
                  <InputTextField
                    name="vehicleNumber"
                    label="License plate"
                    control={control}
                  />
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sm={5}
                >
                  <SelectCrudField
                    name="transporter"
                    label="Transporter"
                    control={control}
                    data={filteredTransporters}
                    optionLabel={['name']}
                    createModalProps={{
                      title: `Add transporter`,
                      description: "Quick add transporter",
                      form: "transporter",
                      formProps: {
                        callback: setValue,
                        showDriver: false,
                        tenant: transport ? transport.tenant : selectedData.filter.tenant,
                      },
                    }}
                    editModalProps={{
                      title: `Edit transporter`,
                      description: "Quick edit a transporter",
                      form: "transporter",
                      formProps: {
                        showDriver: false,
                      },
                    }}
                  />
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sm={5}
                >
                  <SelectCrudField
                    name="driver"
                    label="Driver"
                    control={control}
                    data={filteredDrivers}
                    disabled={!watch('transporter')}
                    disableCreate={!watch('transporter')}
                    optionLabel={['lastName', 'firstName']}
                    createModalProps={{
                      title: `Add a driver to a transporter`,
                      description: "Quick add a driver",
                      form: "driver",
                      formProps: {
                        callback: setValue,
                        args: "driver",
                        transporter: selectedData.filter.transporter,
                        tenant: transport ? transport.tenant : selectedData.filter.tenant,
                      },
                    }}
                    editModalProps={{
                      title: `Edit driver`,
                      description: "Quick edit a driver",
                      form: "driver",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
              <Divider orientation="horizontal" />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" className="form-fields-title">
                Products
              </Typography>
              <Grid item xs={12} className="form-fields-container">
                <Grid item xs={12} sm={12}>
                  <Products
                    name={`${
                      incoming ? "incoming" : "outgoing"
                    }TransportProducts`}
                    label="Products"
                    control={control}
                    register={register}
                    options={products}
                  />
                  {/* <IconButton
                    onClick={() =>
                      dispatch(
                        showChildModal({
                          title: `Add product`,
                          description: "Quick add product",
                          form: "product",
                          formProps: {
                            callback: setValue,
                          },
                        })
                      )
                    }
                  >
                    <AddIcon />
                  </IconButton> */}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
              <Divider orientation="horizontal" />
            </Grid>

            <Grid item sx={{ display: "flex", gap: "5px" }}>
              <Grid item xs={6}>
                <Typography variant="h6" className="form-fields-title">
                  Checklist
                </Typography>
                <Grid item xs={12} className="form-fields-container">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className="transport-medium-select"
                  >
                    <SelectField
                      name="transportMedium"
                      label="Transport Medium"
                      control={control}
                      options={GetTransportMediumOptions()}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className="form-fields-title">
                  Document Languages
                </Typography>
                <Grid item xs={12} className="form-fields-container">
                  <Grid item xs={12} sm={12}>
                    <SelectMultipleField
                      name="documentLanguages"
                      label="Languages"
                      control={control}
                      options={GetLanguageOptions()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {transportMedium && transportMedium === "container" ? (
              <>
                <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
                  <Divider orientation="horizontal" />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" className="form-fields-title">
                    Containers
                  </Typography>
                  <Grid item xs={12} className="form-fields-container">
                    <Grid item xs={12} sm={12}>
                      <Containers
                        name="containers"
                        label="Containers"
                        control={control}
                        register={register}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid item xs={12} className="button-wrapper">
          <Grid container spacing={1}>
            <Grid item>
              <Button
                onClick={() => {
                  dispatch(hideModal());
                }}
                disabled={isSubmitting}
                variant="contained"
              >
                <CloseIcon fontSize="inherit" />
                &nbsp;Close
              </Button>
            </Grid>
            {transport && (
              <Grid item>
                <Button
                  onClick={() => {
                    dispatch(incoming ? removeIncomingTransport(transport) : removeOutgoingTransport(transport));
                  }}
                  disabled={isSubmitting}
                  variant="contained"
                  color="error"
                >
                  <DeleteIcon fontSize="inherit" />
                  &nbsp;Delete
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item sx={{ display: "flex", gap: "5px" }}>
            <Button type="submit" disabled={isSubmitting} variant="contained">
              <SaveIcon fontSize="inherit" />
              &nbsp;Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default TransportForm;
