import React from "react";
import { useFieldArray } from "react-hook-form";
import { NumberField } from "../FormFields/RenderNumberField.tsx";

import { IconButton, Grid } from "@mui/material";
import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";
import { SelectField } from "../FormFields/RenderSelectField.tsx";
import {SubTotalCalculation} from "../FormFields/TotalCalculation.tsx";
import ProductPackaging from "../FormFields/ProductPackaging.tsx";
import ProductUnit from "../FormFields/ProductUnit.tsx";

const Products = ({
  name,
  control,
  register,
  options,
}: any) => {
  const { fields, append, remove, watch } = useFieldArray({
    name: name,
    control,
    rules: {
      required: "Please append at least 1 item",
    },
  });

  const formOptions = options.map((option) =>
    option["@id"]
      ? { label: `${option.unNr} - ${option.nameEn}`, value: option["@id"] }
      : { ...option, label: "" }
  );

  return (
    <>
      <Grid sx={{ maxHeight: '20vh', overflow: 'auto', paddingTop: '10px' }}>
      {fields.map((field, index) => {
        return (
          <section key={field.id}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <SelectField
                  name="product"
                  label="Product"
                  control={control}
                  options={formOptions}
                  {...register(`${name}.${index}.product`, {})}
                />
              </Grid>
              <Grid item xs={2} sx={{ marginBottom: "5px" }}>
                <NumberField
                  name="packagingQuantity"
                  label="Quantity"
                  control={control}
                  {...register(`${name}.${index}.packagingQuantity`, {
                    required: true,
                    valueAsNumber: true,
                  })}
                />
              </Grid>

              <Grid item xs={2} sx={{ marginBottom: "5px" }}>
                <ProductPackaging
                  control={control}
                  products={options}
                  index={index}
                  name={ name }
                />
              </Grid>
              
              <Grid item xs={2} sx={{ marginBottom: "5px" }}>
                <NumberField
                  name="quantity"
                  label="Total Quantity"
                  control={control}
                  {...register(`${name}.${index}.quantity`, {
                    required: true,
                    valueAsNumber: true,
                  })}
                />
              </Grid>

              <Grid item xs={1} sx={{ marginBottom: "5px" }}>
                <ProductUnit
                  control={control}
                  products={options}
                  index={index}
                  name={ name }
                />
              </Grid>
              
              <Grid item xs={1}>
                <SubTotalCalculation
                    control={control}
                    products={options}
                    index={index}
                    name={ name }
                  />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  type="button"
                  color="error"
                  size="small"
                  onClick={() => remove(index)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </section>
        );
      })}
      </Grid>
      <IconButton
        type="button"
        color="info"
        size="small"
        onClick={() => {
          append({
            product: "",
            quantity: 0,
          });
        }}
      >
        <AddIcon />
      </IconButton>
    </>
  );
};

export default Products;
