import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout.tsx";
import { getProducts } from "../../data/product.js";
import { getClassifications } from "../../data/classifications.js";
import { getPackagings } from "../../data/packaging.js";
import { capitalizeFirstLetter } from "../Helpers.tsx";
import { selectFilteredPackagings, selectFilteredProducts } from "../../data/selectors/filterSelector.js";
import RenderProductList from "../partial/Products.tsx";
import { addActiveFilterValue } from "../../data/filter.tsx";

import {
  GridRowsProp,
} from "@mui/x-data-grid";

import {
  Grid,
  Typography,
} from "@mui/material";

const ProductList = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const selectedData = useSelector((state) => ({
    classifications: state.classifications,
    products: state.products,
  }));

  const filteredProducts = useSelector(selectFilteredProducts);
  const filteredPackagings = useSelector(selectFilteredPackagings);

  useEffect(() => {
    dispatch(addActiveFilterValue(true));
    dispatch(getProducts());
    dispatch(getPackagings());
    dispatch(getClassifications());
  }, [dispatch]);

  const { classifications, products } = selectedData;

  useEffect(() => {
    setRows(formatDataGridData(filteredProducts));
  }, [filteredProducts]);

  const formatDataGridData: any = (products): GridRowsProp[] => {
    let formattedProducts: GridRowsProp[] = [];

    products.map((product) => {
      formattedProducts.push({
        id: product["@id"],
        active: product.active,
        descriptionEn: product.descriptionEn,
        classification: product.classification,
        unNr: product.unNr ? `UN${product.unNr}` : '',
        classifications: product.classifications,
        transporType: product.transportType ?  capitalizeFirstLetter(product.transportType) : '',
        category: product.category,
        sdsFile: product.sdsFile,
      });
    });

    return formattedProducts;
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={12} sm={12} padding={1}>
          <Grid item  sx={{ height: 'auto' }}>
              <Grid
                className="settings-page-header"
                item
                xs={12}
                sm={12}
                padding={1}
              >
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Products
                  </Typography>
                </Grid>
                <Grid item></Grid>
              </Grid>
              <RenderProductList
                products={filteredProducts}
                packagings={filteredPackagings}
                classifications={classifications.data}
                rows={rows}
                editable={false}
                isLoading={products.loading}
              />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ProductList;
