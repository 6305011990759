import axios from "axios";
import { hideModal } from "./modal.tsx";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_PACKAGINGS = "FETCH_PACKAGINGS";
const FETCH_PACKAGINGS_SUCCESS = "FETCH_PACKAGINGS_SUCCESS";
const FETCH_PACKAGINGS_ERROR = "FETCH_PACKAGINGS_ERROR";
const PUT_PACKAGING = "PUT_PACKAGING";
const POST_PACKAGING = "POST_PACKAGING";
const DELETE_PACKAGING = "DELETE_PACKAGING";
const FETCH_NEXT_PACKAGINGS = "FETCH_NEXT_PACKAGINGS";
const FETCH_NEXT_PACKAGINGS_SUCCESS = "FETCH_NEXT_PACKAGINGS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getPackagings = (tenant) => async (dispatch) => {
  dispatch(loadPackagings());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/packagings?page=1${ tenant ?
      `&tenant=${tenant['@id']}`
    : ''}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No packagings found"));
  } else {
    dispatch(
      setPackagings(
        response.data["hydra:member"],
        parseInt(response.data["hydra:totalItems"], 10)
      )
    );
    if (
      parseInt(response.data["hydra:totalItems"], 10) >
      response.data["hydra:member"].length
    ) {
      dispatch(getNextPackagings(tenant));
    }
  }
};

export const getNextPackagings = (tenant) => async (dispatch, getState) => {
  const {
    packagings: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextPackagings(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/packagings?page=${pageToLoad}${ tenant ?
      `&tenant=${tenant['@id']}`
    : ''}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextPackagings(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextPackagings());
  }
};

export const updatePackaging =
  (packaging) => async (dispatch, getState) => {
    dispatch(putPackaging());
    const {
      packagings: {
        data,
        progress: { total },
      },
    } = getState();

    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/packagings/${packaging[
        "@id"
      ].replace("/api/packagings/", "")}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: packaging,
    });

    if (response.status === 200) {
      const resultData = data.map((packaging) => {
        if (packaging["@id"] === response.data["@id"]) {
          return response.data;
        }

        return packaging;
      });

      dispatch(setPackagings(resultData, total));
      dispatch(hideModal());
    } else {
      setError("Something went wrong updating");
    }
  };

export const createPackaging = (packaging) => async (dispatch, getState) => {
    dispatch(postPackaging());
    const {
      packagings: {
        data,
        progress: { total },
      },
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/packagings`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: packaging,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];
  
      dispatch(setPackagings(resultData, total));
      dispatch(hideModal());
    } else {
      setError("Something went wrong deleting");
    }
  };

export const removePackaging = (packaging) => async (dispatch, getState) => {
  dispatch(putPackaging());
  const {
    packagings: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}${packaging["@id"].replace("/api", "")}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== packaging["@id"]);

    dispatch(setPackagings(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadPackagings = () => ({ type: FETCH_PACKAGINGS });

export const putPackaging = () => ({ type: PUT_PACKAGING });

export const postPackaging = () => ({ type: PUT_PACKAGING });

export const deletePackaging = () => ({ type: DELETE_PACKAGING });

export const setPackagings = (packagings, total) => ({
  type: FETCH_PACKAGINGS_SUCCESS,
  payload: { packagings, total },
});

export const loadNextPackagings = (page) => ({
  type: FETCH_NEXT_PACKAGINGS,
  payload: page,
});
export const setNextPackagings = (packagings) => ({
  type: FETCH_NEXT_PACKAGINGS_SUCCESS,
  payload: packagings,
});

export const setError = (msg) => ({
  type: FETCH_PACKAGINGS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const packagingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PACKAGINGS:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_PACKAGINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.packagings,
        progress: {
          ...state.progress,
          loaded: payload.packagings.length,
          pages: Math.ceil(payload.total / payload.packagings.length),
          total: payload.total,
        },
      };

    case PUT_PACKAGING:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_PACKAGING:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_PACKAGING:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_PACKAGINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_PACKAGINGS:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_PACKAGINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    default:
      return state;
  }
};
export default packagingReducer;
