import * as React from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux";
import { InputTextField } from '../FormFields/RenderInputTextField.tsx';
import { createContact, updateContact } from '../../data/contacts.js';
import {
    hideModal,
    hideChildModal,
  } from "../../data/modal.tsx";

import {
    Button,
    Divider,
    Grid,
    Typography,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

const ContactForm = ({ contact, callback, args }) => {    
    const dispatch = useDispatch();

    const selectedData = useSelector((state) => ({
        modal: state.modal
    }));

    const { handleSubmit, control, formState } = useForm({
        defaultValues: contact,
    });
    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler = (data) => {
        let formData = data;

        if (contact) {
            dispatch(updateContact(formData));
        } else {
            dispatch(createContact(formData, callback, args));
        }
    };

    const handleCloseForm = () => {
        if (selectedData.modal.childModal) {
          dispatch(hideChildModal());
        } else {
          dispatch(hideModal());
        }
      };
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12}>
                        <Typography variant='h6' className='form-fields-title'>
                            Contact Information
                        </Typography>
                        <Grid item xs={12} className='form-fields-container'>
                            <Grid item xs={12} sm={4}>
                                <InputTextField
                                    name='name'
                                    label='Name'
                                    control={ control }
                                    required
                                />
                            </Grid>
                            <Grid item  xs={12} sm={4}>
                                <InputTextField
                                    name='phoneNumber'
                                    label='Phone Number'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item  xs={12} sm={4}>
                                <InputTextField
                                    name='email'
                                    label='Email'
                                    control={ control }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
                        <Divider orientation="horizontal" />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography variant='h6' className='form-fields-title'>
                            Address
                        </Typography>
                        <Grid item xs={12} className='form-fields-container'>
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.number'
                                    label='Number'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={4}>
                                <InputTextField
                                    name='address.street'
                                    label='Street'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.postalCode'
                                    label='Postal Code'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.city'
                                    label='City'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.country'
                                    label='Country'
                                    control={ control }
                                />
                            </Grid> 
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='button-wrapper'>
                        <Grid item>
                            <Button 
                                onClick={() => {handleCloseForm()}}
                                disabled={ isSubmitting }
                                variant='contained'
                            >
                                <CloseIcon fontSize="inherit" />&nbsp;Close
                            </Button>
                        </Grid>
                        <Button 
                            type='submit'
                            disabled={ isSubmitting }
                            variant='contained'
                        >
                            <SaveIcon fontSize="inherit" />&nbsp;Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default ContactForm;