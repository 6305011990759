import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIncomingTransports } from "../../data/incomingTransport.js";
import { getOutgoingTransports } from "../../data/outgoingTransport.js";
import { getClassifications } from "../../data/classifications.js";
import ReportHeader from "./ReportHeader.tsx";

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const ADRReport = ({ year, incoming, title }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    classifications: [],
  });
  const selectedData = useSelector((state) => ({
    incomingTransports: state.incomingTransports,
    outgoingTransports: state.outgoingTransports,
    classifications: state.classifications,
  }));

  useEffect(() => {
      dispatch(getIncomingTransports());
      dispatch(getOutgoingTransports());
    dispatch(getClassifications());
  }, [dispatch]);

  const { incomingTransports, outgoingTransports, classifications } =
    selectedData;
  const transports = incoming ? incomingTransports : outgoingTransports;

  const transportProductsPerMonth = {
    January: [],
    February: [],
    March: [],
    April: [],
    June: [],
    July: [],
    August: [],
    September: [],
    October: [],
    November: [],
    December: [],
  };

  let tableData = {
    classifications: [],
  };

  const formatClassificationData = (intMonth) => {
    let month = new Date(2024, intMonth, 1).toLocaleString("default", {
      month: "long",
    });

    for (const classification of classifications.data) {
      let transportsFilteredByClassifications = transportProductsPerMonth[
        month
      ].filter((transportProduct) =>
        transportProduct?.product?.classifications
          .map((cl) => cl["@id"])
          .includes(classification["@id"])
      );
      if (transportsFilteredByClassifications.length === 0) {
        continue;
      }

      let classificationObject = tableData.classifications.find(
        (clas) => clas.type === classification.type
      );

      for (const transportsFilteredByClassification of transportsFilteredByClassifications) {
        let unNr = transportsFilteredByClassification?.product.unNr;
        let quantity = transportsFilteredByClassification?.quantity;
        let productObject = classificationObject?.products.find(
          (product) => product.unNr === unNr
        );

        if (productObject && productObject !== undefined) {
          let monthlyQuantity = productObject?.values.find(
            (value) => value.month === month
          );

          if (monthlyQuantity === undefined) {
            productObject.values = [
              ...productObject.values,
              { quantity: quantity, month: month },
            ];
          } else {
            monthlyQuantity.quantity += quantity;
          }

          let monthTotal = classificationObject.monthTotals.find(
            (value) => value.month === month
          );

          if (monthTotal === undefined) {
            monthTotal = 0;
          } else {
            monthTotal = monthTotal.total;
          }

          let monthTotals = classificationObject.monthTotals.filter(
            (value) => value.month !== month
          );

          classificationObject.monthTotals = [
            ...monthTotals,
            { total: (monthTotal += quantity), month: month },
          ];
        }
        if (classificationObject && productObject === undefined) {
          classificationObject.products = [
            ...classificationObject.products,
            { unNr: unNr, values: [{ quantity: quantity, month: month }] },
          ];

          let monthTotal = classificationObject.monthTotals.find(
            (value) => value.month === month
          );

          if (monthTotal === undefined) {
            monthTotal = 0;
          } else {
            monthTotal = monthTotal.total;
          }

          let monthTotals = classificationObject.monthTotals.filter(
            (value) => value.month !== month
          );

          classificationObject.monthTotals = [
            ...monthTotals,
            { total: (monthTotal += quantity), month: month },
          ];
        }
        if (productObject === undefined && classificationObject === undefined) {
          classificationObject = {
            type: classification.type,
            products: [
              { unNr: unNr, values: [{ quantity: quantity, month: month }] },
            ],
            monthTotals: [{ total: quantity, month: month }],
          };

          tableData.classifications = [
            ...tableData.classifications,
            classificationObject,
          ];
        }
      }
    }
  };

  const setTransportProductsPerMonth = (integer) => {
    transportProductsPerMonth[
      new Date(2024, integer, 1).toLocaleString("default", { month: "long" })
    ] = transports
      ? transports.data
          .filter(
            (transport) =>
              transport.documentDate &&
              new Date(transport.documentDate).getMonth() === integer
          )
          .map((transport) =>
            incoming
              ? transport.incomingTransportProducts
              : transport.outgoingTransportProducts
          )
          .flat()
      : [];
  };

  useEffect(() => {
    for (let i = 0; i < 12; i++) {
      setTransportProductsPerMonth(i);
      formatClassificationData(i);
    }

    setFormData(tableData);
  }, [transports, classifications]);

  return (
    <>
      <ReportHeader
        title={`Year report ADR ${year}`}
        subTitle={`${title} TRANSPORTS`}
      />
      <Grid container padding={1}>
        {!transports.loading &&
          !transports.error &&
          formData &&
          formData.classifications.length !== 0 &&
          formData.classifications.map((classification) => (
            <>
              <Typography variant="h6">Class: {classification.type}</Typography>
              <TableContainer>
                <Table aria-label="terminal table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>UNnr</TableCell>
                      <TableCell>JAN</TableCell>
                      <TableCell>FEB</TableCell>
                      <TableCell>MAR</TableCell>
                      <TableCell>APR</TableCell>
                      <TableCell>MAY</TableCell>
                      <TableCell>JUN</TableCell>
                      <TableCell>JUL</TableCell>
                      <TableCell>AUGUST</TableCell>
                      <TableCell>SEPTEMBER</TableCell>
                      <TableCell>OCTOBER</TableCell>
                      <TableCell>NOVEMBER</TableCell>
                      <TableCell>DECEMBER</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {classification &&
                      classification.products.length !== 0 &&
                      classification.products.map((product) => (
                        <TableRow>
                          <TableCell>{product.unNr}</TableCell>
                          <TableCell>
                            {product.values &&
                              product.values.find(
                                (value) => value.month === "January"
                              )?.quantity}
                          </TableCell>
                          <TableCell>
                            {product.values &&
                              product.values.find(
                                (value) => value.month === "February"
                              )?.quantity}
                          </TableCell>
                          <TableCell>
                            {product.values &&
                              product.values.find(
                                (value) => value.month === "March"
                              )?.quantity}
                          </TableCell>
                          <TableCell>
                            {product.values &&
                              product.values.find(
                                (value) => value.month === "April"
                              )?.quantity}
                          </TableCell>
                          <TableCell>
                            {product.values &&
                              product.values.find(
                                (value) => value.month === "May"
                              )?.quantity}
                          </TableCell>
                          <TableCell>
                            {product.values &&
                              product.values.find(
                                (value) => value.month === "June"
                              )?.quantity}
                          </TableCell>
                          <TableCell>
                            {product.values &&
                              product.values.find(
                                (value) => value.month === "July"
                              )?.quantity}
                          </TableCell>
                          <TableCell>
                            {product.values &&
                              product.values.find(
                                (value) => value.month === "August"
                              )?.quantity}
                          </TableCell>
                          <TableCell>
                            {product.values &&
                              product.values.find(
                                (value) => value.month === "September"
                              )?.quantity}
                          </TableCell>
                          <TableCell>
                            {product.values &&
                              product.values.find(
                                (value) => value.month === "October"
                              )?.quantity}
                          </TableCell>
                          <TableCell>
                            {product.values &&
                              product.values.find(
                                (value) => value.month === "November"
                              )?.quantity}
                          </TableCell>
                          <TableCell>
                            {product.values &&
                              product.values.find(
                                (value) => value.month === "December"
                              )?.quantity}
                          </TableCell>
                        </TableRow>
                      ))}
                    <TableRow>
                      <TableCell>TOTALS</TableCell>
                      <TableCell>
                        {classification.monthTotals &&
                          classification.monthTotals.find(
                            (value) => value.month === "January"
                          )?.total}
                      </TableCell>
                      <TableCell>
                        {classification.monthTotals &&
                          classification.monthTotals.find(
                            (value) => value.month === "February"
                          )?.total}
                      </TableCell>
                      <TableCell>
                        {classification.monthTotals &&
                          classification.monthTotals.find(
                            (value) => value.month === "March"
                          )?.total}
                      </TableCell>
                      <TableCell>
                        {classification.monthTotals &&
                          classification.monthTotals.find(
                            (value) => value.month === "April"
                          )?.total}
                      </TableCell>
                      <TableCell>
                        {classification.monthTotals &&
                          classification.monthTotals.find(
                            (value) => value.month === "May"
                          )?.total}
                      </TableCell>
                      <TableCell>
                        {classification.monthTotals &&
                          classification.monthTotals.find(
                            (value) => value.month === "June"
                          )?.total}
                      </TableCell>
                      <TableCell>
                        {classification.monthTotals &&
                          classification.monthTotals.find(
                            (value) => value.month === "July"
                          )?.total}
                      </TableCell>
                      <TableCell>
                        {classification.monthTotals &&
                          classification.monthTotals.find(
                            (value) => value.month === "August"
                          )?.total}
                      </TableCell>
                      <TableCell>
                        {classification.monthTotals &&
                          classification.monthTotals.find(
                            (value) => value.month === "September"
                          )?.total}
                      </TableCell>
                      <TableCell>
                        {classification.monthTotals &&
                          classification.monthTotals.find(
                            (value) => value.month === "October"
                          )?.total}
                      </TableCell>
                      <TableCell>
                        {classification.monthTotals &&
                          classification.monthTotals.find(
                            (value) => value.month === "November"
                          )?.total}
                      </TableCell>
                      <TableCell>
                        {classification.monthTotals &&
                          classification.monthTotals.find(
                            (value) => value.month === "December"
                          )?.total}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ))}
      </Grid>
    </>
  );
};

export default ADRReport;
