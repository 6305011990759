import * as React from 'react';
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import { InputTextField } from '../FormFields/RenderInputTextField.tsx';
import { InputColorField } from '../FormFields/RenderInputColorField.tsx';
import { hideModal } from "../../data/modal.tsx";
import { useDispatch } from "react-redux";

import {
    Button,
    Grid,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';


const LabelForm = ({label}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { handleSubmit, control, formState } = useForm({
        defaultValues: label,
    });
    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler = (data) => {
        axios({
            method: label ? 'PUT' : 'POST',
            url: `${process.env.REACT_APP_API_URL}/labels${ label && label['@id'] ? `/${label['@id'].replace('/api/labels/', '')}` : '' }`,
            headers: {
              "Content-Type": "application/ld+json; charset=utf-8",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: data
        }).then((res) => {
            window.location.href = location.pathname
        }).catch((error) => console.log(error));
    }
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={4}>
                            <InputColorField
                                name='color'
                                label='Color'
                                control={ control }
                                required
                            />
                        </Grid> 
                        <Grid item xs={12} sm={8}>
                            <InputTextField
                                name='name'
                                label='Name'
                                control={ control }
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='button-wrapper'>
                <Button 
                    onClick={() => {dispatch(hideModal())}}
                    disabled={ isSubmitting }
                    variant='contained'
                >
                    <CloseIcon fontSize="inherit" />&nbsp;Close
                </Button>
                    <Button 
                        type='submit'
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <SaveIcon fontSize="inherit" />&nbsp;Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default LabelForm;