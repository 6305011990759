import * as React from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux";
import { InputTextField } from '../FormFields/RenderInputTextField.tsx';
import { DateField } from '../FormFields/RenderDateField.tsx';
import { createDriver, updateDriver } from '../../data/driver.js';
import {
    hideModal,
    hideChildModal,
  } from "../../data/modal.tsx";

import {
    Button,
    Grid,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

const DriverForm = ({ object, callback, args, transporter, tenant }) => {    
    const dispatch = useDispatch();

    const selectedData = useSelector((state) => ({
        modal: state.modal
    }));

    const { handleSubmit, control, formState } = useForm({
        defaultValues: object,
    });
    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler = (data) => {
        let formData = data;

        if (tenant !== undefined) {
            formData = {
                ...formData,
                tenant: tenant['@id'],
            }
        } 

        if (transporter) {
            formData = {
                ...formData,
                transporter: transporter['@id'],
            }
        }

        if (object) {
            dispatch(updateDriver(formData));
        } else {
            dispatch(createDriver(formData, callback, args));
        }
    };

    const handleCloseForm = () => {
        if (selectedData.modal.childModal) {
          dispatch(hideChildModal());
        } else {
            dispatch(hideModal());
        }
    };
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12}>
                        <Grid item xs={12} className='form-fields-container'>
                            <Grid item xs={12} sm={4}>
                                <InputTextField
                                    name='firstName'
                                    label='Firstname'
                                    control={ control }
                                    required
                                />
                            </Grid>
                            <Grid item  xs={12} sm={4}>
                                <InputTextField
                                    name='lastName'
                                    label='Lastname'
                                    control={ control }
                                    required
                                />
                            </Grid> 
                            <Grid item xs={12} sm={4} className="form-field-datetime">
                                <DateField
                                    name="certificateExpirationDate"
                                    label="Certificate Expiration"
                                    control={control}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='button-wrapper'>
                        <Grid item>
                            <Button 
                                onClick={() => {handleCloseForm()}}
                                disabled={ isSubmitting }
                                variant='contained'
                            >
                                <CloseIcon fontSize="inherit" />&nbsp;Close
                            </Button>
                        </Grid>
                        <Button 
                            type='submit'
                            disabled={ isSubmitting }
                            variant='contained'
                        >
                            <SaveIcon fontSize="inherit" />&nbsp;Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default DriverForm;