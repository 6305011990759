import "../../styles/form.css";
import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../data/modal.tsx";
import { updateIncomingTransport } from "../../data/incomingTransports.js";
import { updateOutgoingTransport } from "../../data/outgoingTransports.js";
import { updateUser } from "../../data/users.js";
import { MultipleFileField } from "../FormFields/RenderMultipleFileField.tsx";
import FileView from "../partial/FileView.tsx";
import { updateTraining } from "../../data/training.js";

import {
    Button,
    Divider,
    Grid,
    List as MuiList,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
  } from "@mui/material";

import {
  Close as CloseIcon,
  Save as SaveIcon,
  FileCopySharp as FileCopySharpIcon,
  InsertDriveFileSharp as InsertDriveFileSharpIcon,
} from "@mui/icons-material";

const FileGroupForm = ({
    object,
    viewOnly=false,
}) => {
    const dispatch = useDispatch();

    const selectedData = useSelector((state) => ({
        incomingTransports: state.incomingTransports,
        outgoingTransports: state.outgoingTransports,
        files: state.files,
      }));
    
    const { files } = selectedData;

    const transports = object["@type"].includes("incoming") ? selectedData.incomingTransports.data : selectedData.outgoingTransports.data
    const transport = transports.find(transport => transport["@id"] === object["@id"])

    let existingFiles = object && object.fileGroup
    ? object.fileGroup.files
    : [];

    useEffect(() => {
        if (transport) {
            existingFiles = transport.fileGroup
            ? transport.fileGroup.files
            : []
        } 
      }, [transports]);

    const [selectedFile, setSelectedFile] = useState(existingFiles.length > 0 ? existingFiles[0] : undefined);

    const { handleSubmit, control, formState, register, setValue, watch } = useForm({
      defaultValues: object,
    });

    const { isSubmitting } = formState;
    
    const onSubmit: SubmitHandler = (data) => {
        if (object["@type"].includes("Transport")) {
            object["@type"].includes("incoming") ? dispatch(updateIncomingTransport(data)) : dispatch(updateOutgoingTransport(data))
        }
        if (object["@type"].includes("User")) {
            dispatch(updateUser(data))
        }
        if (object["@type"].includes("Training")) {
            dispatch(updateTraining(data))
        }
    };

    return (
        <>
        <Grid container flexDirection='row' flexWrap='nowrap'>
            <Grid item xs={12} sm={4} sx={{ minWidth: '20vh', paddingRight: '10px'}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    { !viewOnly ? (
                        <MultipleFileField
                            control={control}
                            register={register}
                            setValue={setValue}
                            name="fileGroup.files"
                            existingFiles={existingFiles}
                            fileState={files}
                        />
                    ) : ''}
                    <Grid item>
                        <MuiList>
                            <ListItem disabled>
                                <ListItemIcon>
                                    <FileCopySharpIcon />
                                </ListItemIcon>
                                <ListItemText primary="Files" />
                            </ListItem>
                            { existingFiles && existingFiles.length > 0 &&
                            existingFiles.map((file:any, index:any) => (
                                <ListItem 
                                disablePadding 
                                className={`js-existing-file-element ${ file === selectedFile ? 'active-menu' : '' }`}
                                data-existing-file-id={file['@id']}
                                key={`file-view-existing-file-${index}`}
                                >
                                <ListItemButton 
                                    onClick={() => setSelectedFile(file) }
                                >
                                    <ListItemIcon>
                                    <InsertDriveFileSharpIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`${ file.description }`} />
                                </ListItemButton>
                                </ListItem>
                            ))
                            }
                        </MuiList>
                    </Grid>
                    { !viewOnly ? (
                        <Button type="submit" disabled={isSubmitting || files.loading} variant="contained" sx={{ marginTop: '10px', float:'right'}}>
                            <SaveIcon fontSize="inherit" />
                            &nbsp;Save upload
                        </Button>
                    ) : ''}
                </form>
            </Grid>

            <Grid item xs={12} sm={8} sx={{ minWidth: '20vh', maxWidth: '40vh', minHeight: '70vh', borderLeft: '1px solid #DDDDDD' }}>
                { selectedFile ? (
                    <FileView file={selectedFile} />
                ) : 'No file selected'}
            </Grid>
      </Grid>
      <Grid container>
            <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
              <Divider orientation="horizontal" />
            </Grid>

            <Grid item xs={12} className="button-wrapper">
                <Button
                    onClick={() => {
                    dispatch(hideModal());
                    }}
                    disabled={isSubmitting}
                    variant="contained"
                >
                    <CloseIcon fontSize="inherit" />
                    &nbsp;Close
                </Button>
            </Grid>
      </Grid>
      </>
    )
};

export default FileGroupForm;