
import React, {useState} from "react";
import { useForm } from "react-hook-form";
import Layout from "../../Layout.tsx";
import { SelectField } from "../../FormFields/RenderSelectField.tsx";
import { useParams } from "react-router-dom";
import ReportsMenu from "../Menu.tsx";

import {
  Grid,
  Paper,
} from "@mui/material";
import ADRIndex from "../ADRIndex.tsx";

const ADNIndexReport = () => {
  const { type } = useParams();
  const [year, setYear] = useState(new Date().getFullYear())

  const { control } = useForm({
      defaultValues: {
        reportSelectedYear: 2024
      },
  });

  const title = type === 'incoming' ? 'INCOMING' : 'OUTGOING';

  return (
    <Layout>
    <Grid container>
      <Grid item xs={12} sm={2} padding={1}>
        <Grid item component={Paper} sx={{ height: "100%" }}>
            <ReportsMenu activeMenu={ `adn_index_${type}`}/>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} padding={1}>
        <Grid item xs={12} sm={1}>
          <SelectField
            name="reportSelectedYear"
            label="Report year"
            control={control}
            options={[
              {
                value: 2024,
                label: '2024',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12} component={Paper}>
          <Grid item sx={{ height: "80vh", marginTop: 1 }}>
            <ADRIndex year={year} incoming={type === 'incoming'} title={title} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Layout>
  );
};

export default ADNIndexReport;
