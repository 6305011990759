import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout.tsx";
import { getLabels } from "../../../data/labels";
import { Label } from "../../../types/Label";
import { LabelColorFormatter } from "../../Helpers.tsx";
import Modal from "../../Modal.tsx";
import { showModal } from "../../../data/modal.tsx";
import SettingsMenu from "../Menu.tsx";

import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography,
} from "@mui/material";

import { 
  Edit as EditIcon,
} from "@mui/icons-material";

const LabelManage = () => {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
    labels: state.labels,
  }));

  useEffect(() => {
    dispatch(getLabels());
  }, [dispatch]);

  const { labels } = selectedData;

  return (
    <Layout>
    <Modal/>
    <Grid container>
      <Grid item xs={12} sm={2} padding={1}>
        <Grid item component={ Paper }  sx={{ height: 'auto' }}>
          <SettingsMenu activeMenu={ 'label' }/>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} padding={1}>
        <Grid item component={ Paper }  sx={{ height: 'auto' }}>
          <Grid className='settings-page-header' item xs={12} sm={12} padding={1}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                Label
              </Typography>
            </Grid>
            <Grid item>
              <Button 
                  variant='contained'
                  size='small'
                  onClick={() => {
                    dispatch(
                      showModal({
                        title: "Add label",
                        description: "Creates a new label",
                        form: "label",
                      })
                    );
                  }}
                >
                Add Label
              </Button>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={12} padding={1}>
            <Grid item sx={{ height: '80vh' }}>
              <TableContainer>
              <Table aria-label="label table" stickyHeader>
                <TableHead>
                    <TableRow>
                      <TableCell width='80px' align='center'>
                        Color
                      </TableCell>
                      <TableCell>
                        Name
                      </TableCell>
                      <TableCell align='right'></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      { labels && labels.data.length !== 0 && labels.data.map((label: Label) => (
                        <TableRow className='js-collection-item' data-object-id={ label['@id'] }>
                          <TableCell 
                            align='center' 
                            width='80px'
                            sx={{ backgroundColor: LabelColorFormatter(label.color), borderColor:LabelColorFormatter(label.color) }}
                          ></TableCell>
                          <TableCell>{ label.name  }</TableCell>
                          <TableCell align='right'>
                            <IconButton onClick={() => {
                              dispatch(
                                showModal({
                                  title: "Edit label",
                                  description: "Edit a label",
                                  form: "label",
                                  formProps: { label: label },
                                })
                              );
                            }}>
                              <EditIcon size='small'/> 
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
        </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Layout>
  );
};

export default LabelManage;
