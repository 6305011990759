import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addActiveFilterValue, removeActiveFilterValue } from '../../data/filter.tsx';

import {
    Box,
    FormControlLabel,
    Switch,
} from "@mui/material";


const ActiveFilter = () => {
    const dispatch = useDispatch();

    const selectedData = useSelector((state) => ({
        filter: state.filter,
    }));

    const {
        filter,
    } = selectedData;

    useEffect(() => {
        if (filter.active === undefined) {
            dispatch(addActiveFilterValue(true));
        }
    }, [filter]);

    const activeFilterHandler = (event) => {
        dispatch(addActiveFilterValue(event.target.checked));
    }; 
   
    return (
        <Box padding={1}>
            <FormControlLabel control={<Switch defaultChecked  onChange={(e:any) => activeFilterHandler(e)} />} label="Active" />
        </Box>
    )
}

export default ActiveFilter;
