import React from "react";
import Layout from "./Layout.tsx";


import {
  Grid,
} from "@mui/material";

const WelcomePage = () => {
  return (
    <Layout>
        <Grid container sx={{ justifyContent: 'center', alignContent: 'center', height: '80vh' }}>
            <img src='https://i.makeagif.com/media/3-25-2016/eBiLBu.gif' />
        </Grid>
    </Layout>
  );
};

export default WelcomePage;
