import React from "react";
import { useDispatch } from "react-redux";
import { showModal } from "../../data/modal.tsx";
import { fileIconHandler, findObjectsByIds, formatDateToString, hasFile, RenderSites } from "../Helpers.tsx";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridToolbar,
} from "@mui/x-data-grid";  

import {
    Grid,
    IconButton,
    Paper,
    ListItem, 
    Chip, 
    List as MUIList, 
    Stack, 
} from "@mui/material";

import {
  Edit as EditIcon,
  InsertDriveFileSharp as InsertDriveFileSharpIcon,
} from "@mui/icons-material";

export default function RenderUserList ({users, rows, sites, trainings, editable, isLoading}) {
  const dispatch = useDispatch();

  const renderFileGroup = (params: GridRenderCellParams<any, number>) => {
    let user = users.find(
      (user) => user === params.value
    );

    if (!user) {
      return <></>
    }

    const certificates = user.fileGroup;

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: "User Certificates",
              description: "User training certificates",
              form: "filegroup",
              formProps: { 
                object: user,
                viewOnly: !editable
              },
            })
          );
        }}
      >
        <InsertDriveFileSharpIcon 
          size="small" 
          color={ fileIconHandler(certificates) }
          opacity={ hasFile(certificates) ? 1 : 0.4 }
        />
      </IconButton>
    );
  }

  const RenderTrainings = (trainings, editable) => {
    if (trainings === undefined || !trainings) {
      return <></>;
    }
  
    return (
      <>
        <MUIList key={"site-list"} component={Stack} direction="column" sx={{ padding: 0 }}>
          {trainings.map((training: any) => (
            <ListItem
              sx={{ display: "flex", width: 'auto', padding: '4px' }}
              key={ `training-list-${training["@id"]}`  }
              onClick={() => {
                dispatch(
                  showModal({
                    title: `${ training.title ?? '' } ${ training.documentDate ? `(${formatDateToString(training.documentDate)})` : '' }`,
                    description: training.title ?? '',
                    form: "filegroup",
                    formProps: { 
                      object: training,
                      viewOnly: !editable
                    },
                  })
                );
              }}
            >
              <Chip size="small" label={ training.title } />
            </ListItem>
          ))}
        </MUIList>
      </>
    );
  }

  const renderActions = (params: GridRenderCellParams<any, number>) => {
    let user = users.find(
      (user) => user["@id"] === params.value
    );

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: "Edit user",
              description: "Edit a user",
              form: "user",
              formProps: { user: user, sites: sites, trainings },
            })
          );
        }}
      >
        <EditIcon size="small" />
      </IconButton>
    );
  }

  const renderSites = (
    params: GridRenderCellParams<any | undefined>
  ) => {
    if (!params.value) {
      return '';
    }
    
    return RenderSites(findObjectsByIds(sites, params.value));
  };

  const renderTrainings = (
    params: GridRenderCellParams<any | undefined>
  ) => {
    if (!params.value) {
      return '';
    }
    
    return RenderTrainings(findObjectsByIds(trainings, params.value), editable);
  };

  let columns: GridColDef[] = [
    {
      field: "active",
      headerName: "Active",
      minWidth: 150,
      type: "boolean",
    },
    { field: "name", headerName: "Name", minWidth: 200 },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
    },
    {
      field: "sites",
      headerName: "Sites",
      renderCell: renderSites,
      flex: 1,
      minWidth: 200,
      align: "center",
    },
    {
      field: "trainings",
      headerName: "Training Certificates",
      renderCell: renderTrainings,
      flex: 1,
      minWidth: 450,
      align: "center",
    },
    {
      field: "certificates",
      headerName: "User Certificates",
      renderCell: renderFileGroup,
      flex: 1,
      align: "center",
      headerAlign: 'center',
    },
    {
      field: "id",
      headerName: "",
      minWidth: 50,
      renderCell: renderActions,
      editable: false,
      flex: 1,
      align: "right",
    },
  ];

  return (
        <Grid item xs={12} sm={12} component={Paper}>
            <Grid item sx={{ height: "80vh" }}>
              <DataGrid
                hideFooter={true}
                columns={columns}
                sx={{ marginBottom: "25px" }}
                rows={rows}
                getRowHeight={() => 'auto'}
                disableColumnSelector
                disableDensitySelector
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                columnVisibilityModel={{
                    active: editable,
                    id: editable,
                }}
                loading={isLoading}
              />
            </Grid>
        </Grid>
    );
}