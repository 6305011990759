import React, { useState, useRef } from "react";
import axios from "axios";
import SignaturePad from 'react-signature-pad-wrapper';

import {
  Alert,
  Button,
  Grid,
  Typography,
} from "@mui/material";

export const SignatureField: any = ({
  name,
  setValue,
  signatureLabel,
  existingSignature,
}) => {
  const signatoryCanvas = useRef({});
  const [resetSignature, setResetSignature] = useState(!!existingSignature)
  const [signatureConfirmed, setSignatureConfirmed] = useState(false);

  const dataURLtoFile = (dataUrl: string, filename: string) => {
      let arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

    return new File([u8arr], filename, { type: mime });
  }

  const uploadHandler = async (formData) => {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/files`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: formData,
    });

    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      console.log("Something went wrong updating");
    }
  };

  const signatureFileHandler = (fileName) => {
    const formData = new FormData();
    const signature = signatoryCanvas.current.toDataURL('image/png');

    if (!signature) {
      return;
    }

    const signatureFile = dataURLtoFile(signature, `${fileName}.png`)
    
    formData.append("file", signatureFile);
    uploadHandler(formData).then((res) => setValue(name, res))
    setSignatureConfirmed(true)
  };

  const signatureResetHandler = () => {
    setResetSignature(false); 
    setSignatureConfirmed(false);

    if (signatoryCanvas && signatoryCanvas.current) {
      signatoryCanvas.current.clear();
    }
  }

  return (
    <>
      <Grid item sx={{ position: 'relative', width: 'auto !important', marginTop: '5px' }}>
       { existingSignature ? (
         <>
          <Grid item className={ `${ !resetSignature ? 'hidden' : ''}`} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item sx={{ position: 'relative', height: 250, overflow: 'hidden'}}>
              <Button data-html2canvas-ignore="true" onClick={() => signatureResetHandler() }>Reset</Button>
              { signatureLabel ? (
                <Typography fontWeight={700} align='center'>{ signatureLabel }</Typography>
              ) : ''}
              <img height={150} src={ existingSignature.contentUrl } />
            </Grid>
          </Grid>
          </>
        ) : ''}
        <Grid item sx={{ display:'flex', justifyContent: 'space-between'}} data-html2canvas-ignore="true">
          { !resetSignature && !signatureConfirmed ? (
            <>
              <Button size='inherit' onClick={() => signatureFileHandler(name) }>Confirm Signature</Button>
              <Button size='inherit' onClick={() => signatoryCanvas.current.clear() }>Clear</Button>
              { existingSignature ? (
                <Button size='inherit' onClick={() => setResetSignature(true) }>Close</Button>
              ) :  '' }
            </>
          ) : ''}
          { signatureConfirmed ? (
              <Alert sx={{ paddingTop: '0 !important', paddingBottom: '0 !important' }}severity="success" fullWidth>Signature confirmed!</Alert>
          ) : ''}
        </Grid>
        <Grid item sx={{ 
           border: `${ !resetSignature ? '1px solid #DDDDDD' : 'transparent'}`,
           height:  `${ !resetSignature ? '200px' : '0px' }`, 
           overflow: 'hidden',
          }}  
          data-html2canvas-ignore="true"
        >
          <SignaturePad
            height={250}
            ref={signatoryCanvas}
          />
        </Grid>
      </Grid>
    </>
  );
};
