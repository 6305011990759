import React from "react";
import { useDispatch } from "react-redux";
import { showModal } from "../../data/modal.tsx";
import RenderClassificationList from "../partial/ProductClassificationList.tsx";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridToolbar,
} from "@mui/x-data-grid";  

import {
    Grid,
    IconButton,
    Paper,
} from "@mui/material";

import {
    Edit as EditIcon,
    Info as InfoIcon,
    InsertDriveFileSharp as InsertDriveFileSharpIcon,
} from "@mui/icons-material";

export default function RenderProductList ({products, rows, classifications, packagings, editable, isLoading}) {
  const dispatch = useDispatch();

  const renderActions = (params: GridRenderCellParams<any, number>) => {
    let editproduct = products.find(
      (product) => product["@id"] === params.value
    );

    return (
      <>
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: "Product View",
              description: "Product Information",
              form: "productView",
              formProps: { product: editproduct, isModal:true, },
            })
          );
        }}
      >
        <InfoIcon size="small" />
      </IconButton>
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: "Edit product",
              description: "Edit product",
              form: "product",
              formProps: { product: editproduct, classifications: classifications.data, packagings: packagings },
            })
          );
        }}
      >
        <EditIcon size="small" />
      </IconButton>
      </>
    );
  };

  const renderClassifications = (params: GridRenderCellParams<any>) => {    
    return <RenderClassificationList classifications={ params.value } />
  }

  const renderFile = (params: GridRenderCellParams<any, number>) => {
    const sdsFile = params.value;

    if (!sdsFile) {
      return (
          <InsertDriveFileSharpIcon 
          size="small" 
          color={ 'disabled' }
          opacity={ 0.4 }
        />
      )
    }

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: "File View",
              description: sdsFile.description ?? '',
              form: "fileView",
              formProps: { 
                file: sdsFile,
                showCloseButton: true,
              },
            })
          );
        }}
      >
        <InsertDriveFileSharpIcon 
          size="small" 
          color={ '' }
        />
      </IconButton>
    );
  }

  let columns: GridColDef[] = [
    {
      field: "active",
      headerName: "Active",
      minWidth: 150,
      editable: true,
      type: "boolean",
    },
    {
      field: "transporType",
      headerName: "Type",
      minWidth: 100,
    },
    { field: "descriptionEn", headerName: "DescriptionEN", minWidth: 450 },
    {
      field: "unNr",
      headerName: "UN nr",
      minWidth: 100,
    },
    {
      field: "classifications",
      headerName: "Classifications",
      align: 'center',
      headerAlign: 'center',
      minWidth: 250,
      flex: 1,
      renderCell: renderClassifications,
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 100,
      align: "center",
    },
    {
      field: "sdsFile",
      headerName: "SDS File",
      renderCell: renderFile,
      minWidth: 80,
      align: "center",
    },
    {
      field: "id",
      headerName: "",
      minWidth: 50,
      renderCell: renderActions,
      flex: 1,
      align: "right",
    },
  ];

  return (
        <Grid item xs={12} sm={12} component={Paper}>
            <Grid item sx={{ height: "80vh" }}>
                <DataGrid
                    hideFooter={true}
                    columns={columns}
                    sx={{ marginBottom: "25px" }}
                    rows={rows}
                    getRowHeight={() => 'auto'}
                    disableColumnSelector
                    disableDensitySelector
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    columnVisibilityModel={{
                        active: editable,
                        id: editable,
                    }}
                    loading={isLoading}
                />
            </Grid>
        </Grid>
    );
}