import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout.tsx";
import { showModal } from "../../../data/modal.tsx";
import { getTenants } from "../../../data/tenant.js";

import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";

import {
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import {
  Edit as EditIcon,
} from "@mui/icons-material";

const TenantManage = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const selectedData = useSelector((state) => ({
    tenants: state.tenants,
  }));

  useEffect(() => {
    dispatch(getTenants());
  }, [dispatch]);


  const { tenants } = selectedData;

  const renderActions = (params: GridRenderCellParams<any, number>) => {
    let tenant = tenants.data.find(
      (tenant) => tenant["@id"] === params.value
    );

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: "Tenant",
              description: "Tenant edit",
              form: "tenant",
              formProps: { 
                tenant: tenant,
              },
            })
          );
        }} 
      >
        <EditIcon size="small" />
      </IconButton>
    );
  };

  useEffect(() => {
    setRows(formatDataGridData(tenants.data));
  }, [tenants]);

  const formatDataGridData: any = (tenants): GridRowsProp[] => {
    let formattedTenants: GridRowsProp[] = [];

    tenants.map((tenant) => {
      formattedTenants.push({
        id: tenant["@id"],
        active: tenant.isActive,
        name: tenant.name ?? '',
        address: tenant.address ? `${ tenant.address.number ?? '' } ${ tenant.address.street ?? '' }, ${ tenant.address.postalCode ?? '' } ${ tenant.address.city ?? '' }, ${ tenant.address.country ?? '' }` : ''
      });
    });

    return formattedTenants;
  };

  let columns: GridColDef[] = [
    {
      field: "active",
      headerName: "Active",
      minWidth: 150,
      type: "boolean",
    },
    { field: "name", headerName: "Name", minWidth: 450 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 250,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 250,
    },
    {
      field: "id",
      headerName: "",
      minWidth: 50,
      renderCell: renderActions,
      editable: false,
      flex: 1,
      align: "right",
    },
  ];

  return (
    <Layout>
      <Grid container>
      <Grid item xs={12} sm={12} padding={1}>
        <Grid item  sx={{ height: 'auto' }}>
            <Grid
              className="settings-page-header"
              item
              xs={12}
              sm={12}
              padding={1}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  Tenants
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    dispatch(
                      showModal({
                        title: "Tenant",
                        description: "Tenant edit",
                        form: "tenant",
                      })
                    );
                  }}
                >
                  Add Tenant
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} component={Paper}>
              <Grid item sx={{ height: "80vh" }}>
                <DataGrid
                  hideFooter={true}
                  columns={columns}
                  sx={{ marginBottom: "25px" }}
                  rows={rows}
                  getRowHeight={() => 'auto'}
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  loading={tenants.loading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default TenantManage;
