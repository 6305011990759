import React from "react";
import TransportMediumIcon from "../partial/TransportMediumIcon.tsx";

import {  
  Divider,
  Grid, 
} from "@mui/material";

export const GetClassificationOptions = (classifications) => {
    const classificationOptions = [];
  
    if (!classifications || classifications.length === 0) {
        return classificationOptions
    }
  
    classifications.map((classification) => { 
        classificationOptions.push({
        value: classification['@id'],
        label: `${ classification.type }: ${ classification.class }`,
        imagesrc: `/img/classification/${ classification.pictogram }.png`,
      })
    })
  
    return classificationOptions;
}

export const GetPackagingOptions = (packagings) => {
    const packagingOptions = [];
  
    if (!packagings || packagings.length === 0) {
        return packagingOptions
    }
  
    packagings.map((packaging) => { 
        packagingOptions.push({
        value: packaging['@id'],
        label: packaging.nameEn,
      })
    })
  
    return packagingOptions;
}

export const GetSiteOptions = (sites) => {
    const siteOptions = [];
  
    if (!sites || sites.length === 0) {
        return siteOptions
    }
  
    sites.map((site) => { 
      siteOptions.push({
        value: site['@id'],
        label: site.name,
      })
    })
  
    return siteOptions;
}

export const GetTrainingptions = (trainings) => {
    const trainingOptions = [];
  
    if (!trainings || trainings.length === 0) {
        return trainingOptions
    }
  
    trainings.map((training) => { 
      trainingOptions.push({
        value: training['@id'],
        label: training.title,
      })
    })
  
    return trainingOptions;
}
export const GetUserOptions = (users) => {
    const userOptions = [];
  
    if (!users || users.length === 0) {
        return userOptions
    }
  
    users.map((user) => { 
      userOptions.push({
        value: user['@id'],
        label: `${ user.lastName ?? '' } ${ user.firstName ?? '' }`,
      })
    })
  
    return userOptions;
}

export const GetTransportMediumOptions = () => {
    return [
        {
          value: "colli",
          label: "Colli",
          content: (
            <Grid item className='transport-medium-content'></Grid>
          ),
          icon: <TransportMediumIcon type='colli' size='35px'/>,
        },
        {
          value: "tank",
          label: "Tank transport",
          content: (
            <Grid item className='transport-medium-content'>
              xxx
              <Divider/>
              xxxx
            </Grid>
          ),
          icon: <TransportMediumIcon type='tank' size='60px'/>,
        },
        {
          value: "container",
          label: "Container",
          content: (
            <Grid item className='transport-medium-content'></Grid>
          ),
          icon: <TransportMediumIcon type='container' size='35px'/>,
        },
      ];
}

export const GetLanguageOptions = () => {
    return [
        {
          value: "en",
          label: "English",
        },
        {
          value: "nl",
          label: "Nederlands",
        },
        {
          value: "fr",
          label: "Français",
        },
        {
          value: "de",
          label: "Deutsch",
        },
      ];
}
