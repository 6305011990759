import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

import Layout from "../Layout.tsx";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { showModal } from "../../data/modal.tsx";
import { getContacts } from "../../data/contacts.js";
import { getLabels } from "../../data/labels.js";
import { getIncomingTransports } from "../../data/incomingTransports.js";
import { getOutgoingTransports } from "../../data/outgoingTransports.js";
import {
  capitalizeFirstLetter,
  fileIconHandler,
  formateDate,
  hasFile,
  RenderLabels,
} from "../Helpers.tsx";
import { getTransporters } from "../../data/transporters.js";
import { getProducts } from "../../data/product.js";
import { getSites } from "../../data/site.js";
import {
  selectFilteredIncomingTransports,
  selectFilteredLabels,
  selectFilteredOutgoingTransports,
  selectFilteredProducts,
  selectFilteredSites,
  selectFilteredTransporters,
} from "../../data/selectors/filterSelector.js";
import { getTenants } from "../../data/tenant.js";
import { getDrivers } from "../../data/driver.js";
import UserPreferredLanding from "../partial/PreferredLanding.tsx";

import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";

import {
  Chip,
  Paper,
  IconButton,
  Button,
  Grid,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import {
  AssignmentSharp as AssignmentSharpIcon,
  Edit as EditIcon,
  ChecklistRtlSharp as ChecklistRtlSharpIcon,
  Rule as RuleIcon,
  InsertDriveFileSharp as InsertDriveFileSharpIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";

import {
  addTransportLabelFilterValue,
  removeTransportLabelFilterValue,
  addTransportSiteFilterValue,
  removeTransportSiteFilterValue,
  addTenantFilterValue,
  removeTenantFilterValue,
  addActiveFilterValue,
  addTransportTypeFilterValue,
} from "../../data/filter.tsx";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TransportDocument = () => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const title = type === "incoming" ? "Incoming" : "Outgoing";
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

  const selectedData = useSelector((state) => ({
    contacts: state.contacts,
    products: state.products,
    incomingTransports: state.incomingTransports,
    outgoingTransports: state.outgoingTransports,
    filter: state.filter,
    tenants: state.tenants,
    user: state.currentUser.data,
  }));

  const filteredIncomingTransports = useSelector(selectFilteredIncomingTransports);
  const filteredOutgoingTransports = useSelector(selectFilteredOutgoingTransports);
  const filteredTransports = type === "incoming" ? filteredIncomingTransports : filteredOutgoingTransports;
  const filteredSites = useSelector(selectFilteredSites);
  const filteredLabels = useSelector(selectFilteredLabels);
  const filteredProducts = useSelector(selectFilteredProducts);
  const filteredTransporters = useSelector(selectFilteredTransporters);

  const renderLabels = (params: GridRenderCellParams<any | undefined>) => {
    return RenderLabels(params.value);
  };

  useEffect(() => {
    if (type === "incoming") {
      dispatch(getIncomingTransports());
    } else {
      dispatch(getOutgoingTransports());
    }
  }, [dispatch, type]);

  useEffect(() => {
    dispatch(getContacts());
    dispatch(getLabels());
    dispatch(getTransporters());
    dispatch(getDrivers());
    dispatch(getProducts());
    dispatch(getTenants());
    dispatch(getSites());
    dispatch(addActiveFilterValue(true));
    dispatch(addTransportTypeFilterValue(type));
  }, [dispatch, type]);

  const { contacts, incomingTransports, outgoingTransports, filter, user, tenants } = selectedData;
  const transports = (type === "incoming") ? incomingTransports : outgoingTransports

  const superAdmin =
    user &&
    user.roles &&
    user.roles.includes("ROLE_SUPER_ADMIN");

  const checkListIconButtonHandler = (checklist) => {
    if (!checklist) {
      return "";
    }

    return checklist.every((answer) => answer && answer === true)
      ? "success"
      : "error";
  };

  const checkListIconHandler = (checklist) => {
    if (!checklist) {
      return <ChecklistRtlSharpIcon size="small" />;
    }

    return checklist.every((answer) => answer && answer === true) ? (
      <CheckBoxIcon size="small" />
    ) : (
      <RuleIcon size="small" />
    );
  };

  const toggleLabelFilterHandler = (labelId) => {
    if (!filter.transport.labels.includes(labelId)) {
      dispatch(addTransportLabelFilterValue(labelId));
    } else {
      dispatch(removeTransportLabelFilterValue(labelId));
    }
  };

  const toggleSiteFilterHandler = (siteId) => {
    if (!filter.transport.sites.includes(siteId)) {
      dispatch(addTransportSiteFilterValue(siteId));
    } else {
      dispatch(removeTransportSiteFilterValue(siteId));
    }
  };

  const toggleTenantFilterHandler = (tenant) => {
    if (!filter.tenant || filter.tenant["@id"] !== tenant["@id"]) {
      dispatch(addTenantFilterValue(tenant));
    } else {
      dispatch(removeTenantFilterValue(tenant));
    }
  };

  const renderFiles = (params: GridRenderCellParams<any, number>) => {
    let edittransport = transports.data.find(
      (transport) => transport["@id"] === params.value
    );

    if (!edittransport) {
      return <></>;
    }

    const transportFiles = edittransport.fileGroup;

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: "Transport files",
              description: "Transport Files",
              form: "filegroup",
              formProps: {
                object: edittransport,
              },
            })
          );
        }}
      >
        <InsertDriveFileSharpIcon
          size="small"
          color={fileIconHandler(transportFiles)}
          opacity={hasFile(transportFiles) ? 1 : 0.4}
        />
      </IconButton>
    );
  };

  const renderNumber = (params: GridRenderCellParams<any, number>) => {
    if (!params.value) {
      return <></>;
    }

    return (
      <Chip
        className="hoverable-chip-button"
        label={params.value.number}
        onDelete={() => {
          dispatch(
            showModal({
              title: "Edit transport",
              description: "Edit transport",
              form: "transport",
              formProps: {
                transport: params.value,
                labels: filteredLabels,
                contacts: contacts.data,
                products: filteredProducts,
                transporters: filteredTransporters,
                sites: filteredSites,
                incoming: type === "incoming",
              },
            })
          );
        }}
        deleteIcon={<EditIcon />}
      />
    );
  };

  const renderActions = (params: GridRenderCellParams<any, number>) => {
    let edittransport = transports.data.find(
      (transport) => transport["@id"] === params.value
    );

    if (!edittransport) {
      return <></>;
    }

    return (
      <>
        <IconButton
          onClick={() => {
            dispatch(
              showModal({
                title: "ADR Transport",
                description: "ADR transport document",
                form: "adrtransport",
                formProps: {
                  transport: edittransport,
                  type: type,
                },
              })
            );
          }}
        >
          <AssignmentSharpIcon size="small" />
        </IconButton>
        <IconButton
          color={checkListIconButtonHandler(edittransport.checkList)}
          onClick={() => {
            dispatch(
              showModal({
                title: `${title} transport checklist`,
                description: "Checklist",
                form: "transportchecklist",
                formProps: {
                  transport: edittransport,
                  type: type,
                },
              })
            );
          }}
        >
          {checkListIconHandler(edittransport.checkList)}
        </IconButton>
      </>
    );
  };

  const formatDataGridData: any = (transports): GridRowsProp[] => {
    let formattedTransports: GridRowsProp[] = [];
    transports.map((transport) => {
      formattedTransports.push({
        id: transport["@id"],
        number: transport,
        date: formateDate(transport.documentDate),
        labels: transport.labels,
        status: transport.status ? capitalizeFirstLetter(transport.status) : "",
        files: transport["@id"],
        contact:
          type === "incoming"
            ? transport?.fromContact?.name
            : transport?.toContact?.name,
        transporter: transport?.transporter?.name,
      });
    });

    return formattedTransports;
  };

  let columns: GridColDef[] = [
    {
      field: "number",
      headerName: "Number",
      renderCell: renderNumber,
      minWidth: 150,
      display: "flex",
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      display: "flex",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      display: "flex",
      flex: 1,
    },
    {
      field: "files",
      headerName: "Files",
      renderCell: renderFiles,
      minWidth: 80,
      align: "center",
    },
    {
      field: "labels",
      headerName: "Labels",
      renderCell: renderLabels,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      display: "flex",
    },
    {
      field: "contact",
      headerName: `${type === "incoming" ? "From" : "To"}`,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      display: "flex",
      flex: 1,
    },
    {
      field: "transporter",
      headerName: "Transporter",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      display: "flex",
      flex: 1,
    },
    {
      field: "id",
      headerName: "",
      width: 100,
      renderCell: renderActions,
      editable: false,
      align: "right",
    },
  ];

  return (
    <Layout>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <Grid item>
          <Typography variant="h6">{title} Transport</Typography>
        </Grid>
        <Grid item>
          {user && (filter.tenant || !superAdmin) ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                dispatch(
                  showModal({
                    title: `${title} transport creation`,
                    description: "Creates a new transport",
                    form: "transport",
                    formProps: {
                      contacts: contacts.data,
                      labels: filteredLabels,
                      products: filteredProducts,
                      transporters: filteredTransporters,
                      incoming: type === "incoming",
                      sites: filteredSites,
                      tenant: filter.tenant ? filter.tenant : user.tenant,
                      transportFilter: filter.transport,
                    },
                  })
                );
              }}
            >
              Add
            </Button>
          ) : (
            ""
          )}
          <UserPreferredLanding/>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} sx={{ display: "flex", gap: "5px" }}>
        <Grid
          item
          component={Paper}
          sx={{ height: "80vh", minWidth: "10%", padding: "4px" }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "14px",
              borderBottom: "1px solid #DDDDDD",
            }}
          >
            YEAR
          </Typography>
          <Grid item sx={{ padding: "4px" }}>
            <Chip label="2024" />
          </Grid>
          {superAdmin && tenants && tenants.data.length > 0 ? (
            <>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "14px",
                  borderBottom: "1px solid #DDDDDD",
                }}
              >
                TENANT
              </Typography>
              <Grid
                item
                sx={{
                  padding: "4px",
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: "25vh",
                  overflow: "auto",
                }}
              >
                {tenants.data.map((tenant) => (
                  <Chip
                    onClick={() => toggleTenantFilterHandler(tenant)}
                    variant={
                      filter.tenant && filter.tenant["@id"] === tenant["@id"]
                        ? ""
                        : "outlined"
                    }
                    sx={{ justifyContent: "flex-start", marginBottom: "4px" }}
                    label={tenant.name}
                  />
                ))}
              </Grid>
            </>
          ) : (
            ""
          )}
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "14px",
              borderBottom: "1px solid #DDDDDD",
            }}
          >
            SITES
          </Typography>
          <Grid
            item
            sx={{
              padding: "4px",
              display: "flex",
              flexDirection: "column",
              maxHeight: "25vh",
              overflow: "auto",
            }}
          >
            {(!superAdmin || filter.tenant) &&
              filteredSites.map((site) => (
                <Chip
                  onClick={() => toggleSiteFilterHandler(site["@id"])}
                  variant={
                    filter.transport.sites.includes(site["@id"])
                      ? ""
                      : "outlined"
                  }
                  sx={{ justifyContent: "flex-start", marginBottom: "4px" }}
                  label={site.name}
                />
              ))}
          </Grid>
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "14px",
              borderBottom: "1px solid #DDDDDD",
            }}
          >
            LABELS
          </Typography>
          <Grid
            item
            sx={{
              padding: "4px",
              display: "flex",
              flexDirection: "column",
              maxHeight: "25vh",
              overflow: "auto",
            }}
          >
            {(!superAdmin || filter.tenant) &&
              filteredLabels.map((label) => (
                <Chip
                  onClick={() => toggleLabelFilterHandler(label["@id"])}
                  variant={
                    filter.transport.labels.includes(label["@id"])
                      ? ""
                      : "outlined"
                  }
                  sx={{ justifyContent: "flex-start", marginBottom: "4px" }}
                  avatar={
                    <Grid
                      item
                      sx={{
                        maxHeight: "15px",
                        minWidth: "8px !important",
                        maxWidth: "8px !important",
                        backgroundColor: label.color ?? "#FFFFFF",
                        border: "1px solid #DDDDDD",
                      }}
                    ></Grid>
                  }
                  label={label.name}
                />
              ))}
          </Grid>
        </Grid>
        <Grid
          item
          component={Paper}
          sx={{ height: "80vh", width: isPortrait ? "90%" : "100%" }}
        >
          <DataGrid
            columns={columns}
            sx={{ marginBottom: "25px" }}
            rows={formatDataGridData(filteredTransports)}
            getRowHeight={() => "auto"}
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            columnVisibilityModel={{
              labels: !isPortrait,
            }}
            loading={transports.loading}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default TransportDocument;
