import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout.tsx";
import { showModal } from "../../../data/modal.tsx";
import TenantFilter from "../../partial/TenantFilter.tsx";
import { getPackagings } from "../../../data/packaging.js";

import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";

import { Button, Grid, IconButton, Paper, Typography } from "@mui/material";

import { Edit as EditIcon } from "@mui/icons-material";
import { selectFilteredPackagings } from "../../../data/selectors/filterSelector.js";

const PackagingManage = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const selectedData = useSelector((state) => ({
    filter: state.filter,
    packagings: state.packagings,
  }));

  const filteredPackagings = useSelector(selectFilteredPackagings);

  useEffect(() => {
    dispatch(getPackagings());
  }, [dispatch]);

  const { filter, packagings } = selectedData;

  const renderActions = (params: GridRenderCellParams<any, number>) => {
    let packaging = filteredPackagings.find(
      (packaging) => packaging["@id"] === params.value
    );

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: "Edit Packaging",
              description: "Edit a packaging",
              form: "packaging",
              formProps: { packaging: packaging },
            })
          );
        }}
      >
        <EditIcon size="small" />
      </IconButton>
    );
  };

  useEffect(() => {
    setRows(formatDataGridData(filteredPackagings));
  }, [filteredPackagings]);

  const formatDataGridData: any = (packagings): GridRowsProp[] => {
    let formattedPackagings: GridRowsProp[] = [];

    packagings.map((packaging) => {
      formattedPackagings.push({
        id: packaging["@id"],
        nameEn: packaging.nameEn,
        nameNl: packaging.nameNl,
        nameFr: packaging.nameFr,
        nameDe: packaging.nameDe,
        multiplier: packaging.multiplier,
      });
    });

    return formattedPackagings;
  };

  let columns: GridColDef[] = [
    { field: "nameEn", headerName: "Name En", flex: 1, minWidth: 150, },
    { field: "nameNl", headerName: "Name NL", flex: 1, minWidth: 150, },
    { field: "nameFr", headerName: "Name FR", flex: 1, minWidth: 150, },
    { field: "nameDe", headerName: "Name DE", flex: 1, minWidth: 150, },
    { field: "multiplier", headerName: "Multiplier", flex: 1, minWidth: 150, },
    {
      field: "id",
      headerName: "",
      minWidth: 50,
      renderCell: renderActions,
      editable: false,
      flex: 1,
      align: "right",
    },
  ];

  return (
    <Layout>
      <Grid container>
        <Grid item xs={12} sm={2} padding={1}>
          <Grid item component={Paper} sx={{ height: "100%" }}>
            <TenantFilter />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10} padding={1}>
          <Grid item sx={{ height: "auto" }}>
            <Grid
              className="settings-page-header"
              item
              xs={12}
              sm={12}
              padding={1}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  Packagings
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    dispatch(
                      showModal({
                        title: "Add Packaging",
                        description: "Creates a new packaging",
                        form: "packaging",
                        formProps: {
                          tenant: filter.tenant,
                        },
                      })
                    );
                  }}
                >
                  Add Packaging
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} component={Paper}>
              <Grid item sx={{ height: "80vh" }}>
                <DataGrid
                  hideFooter={true}
                  columns={columns}
                  sx={{ marginBottom: "25px" }}
                  rows={rows}
                  getRowHeight={() => "auto"}
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  loading={packagings.loading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default PackagingManage;
