import * as React from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { InputTextField } from '../FormFields/RenderInputTextField.tsx';
import { hideModal } from "../../data/modal.tsx";
import { useDispatch } from "react-redux";
import { createTraining, updateTraining } from '../../data/training.js';
import { DateField } from '../FormFields/RenderDateField.tsx';
import { SelectMultipleField } from '../FormFields/RenderSelectMultipleField.tsx';
import { GetUserOptions } from './SelectOptionsFormatter.tsx';

import {
    Button,
    Divider,
    Grid,
    Typography,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

const TrainingForm = ({training, tenant, users}) => {
    const dispatch = useDispatch();

    const { handleSubmit, control, formState } = useForm({
        defaultValues: training,
    });
    
    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler = (data) => {
        let formData = data;
    
        if (tenant !== undefined) {
            formData = {
                ...data,
                tenant: tenant['@id'],
            }
        } 

        if (training) {
            dispatch(updateTraining(formData));
        } else {
            dispatch(createTraining(formData));
        }
    };
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={6}>
                            <InputTextField
                                name='title'
                                label='Title'
                                control={ control }
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputTextField
                                name='certificateReceived'
                                label='Certificate Received'
                                control={ control }
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} className="form-field-datetime">
                            <DateField
                                name="documentDate"
                                label="Date"
                                control={control}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
                    <Divider orientation="horizontal" />
                </Grid>

                <Grid item xs={6}>
                        <Typography variant="h6" className="form-fields-title">
                            Users
                        </Typography>
                        <Grid item xs={12} className="form-fields-container">
                            <Grid item xs={12} sm={12}>
                                <SelectMultipleField
                                    name="trainingUsers"
                                    label="Users"
                                    control={control}
                                    options={GetUserOptions(users)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                <Grid item xs={12} className='button-wrapper'>
                <Button 
                    onClick={() => {dispatch(hideModal())}}
                    disabled={ isSubmitting }
                    variant='contained'
                >
                    <CloseIcon fontSize="inherit" />&nbsp;Close
                </Button>
                    <Button 
                        type='submit'
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <SaveIcon fontSize="inherit" />&nbsp;Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default TrainingForm;