import axios from "axios";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_LABELS = "FETCH_LABELS";
const FETCH_LABELS_SUCCESS = "FETCH_LABELS_SUCCESS";
const FETCH_LABELS_ERROR = "FETCH_LABELS_ERROR";
const FETCH_NEXT_LABELS = "FETCH_NEXT_LABELS";
const FETCH_NEXT_LABELS_SUCCESS = "FETCH_NEXT_LABELS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getLabels = (tenant) => async (dispatch) => {
  dispatch(loadLabels());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/labels?page=1${ tenant ?
      `&tenant=${tenant['@id']}`
    : ''}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  
  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No labels found"));
  } else {
    dispatch(
      setLabels(
        response.data["hydra:member"],
        parseInt(response.data["hydra:totalItems"], 10)
      )
    );
    if (
      parseInt(response.data["hydra:totalItems"], 10) >
      response.data["hydra:member"].length
    ) {
      dispatch(getNextLabels(tenant));
    }
  }
};

export const getNextLabels = (tenant) => async (dispatch, getState) => {
  const {
    labels: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextLabels(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/labels?page=${pageToLoad}${ tenant ?
      `&tenant=${tenant['@id']}`
    : ''}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextLabels(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextLabels());
  }
};

export const loadLabels = () => ({ type: FETCH_LABELS });

export const setLabels = (labels, total) => ({
  type: FETCH_LABELS_SUCCESS,
  payload: { labels, total },
});

export const loadNextLabels = (page) => ({
  type: FETCH_NEXT_LABELS,
  payload: page,
});
export const setNextLabels = (labels) => ({
  type: FETCH_NEXT_LABELS_SUCCESS,
  payload: labels,
});

export const setError = (msg) => ({
  type: FETCH_LABELS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const labelsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_LABELS:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_LABELS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.labels,
        progress: {
          ...state.progress,
          loaded: payload.labels.length,
          pages: Math.ceil(payload.total / payload.labels.length),
          total: payload.total,
        },
      };

    case FETCH_LABELS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_LABELS:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_LABELS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    default:
      return state;
  }
};

export default labelsReducer;
