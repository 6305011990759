import React, {useState}  from 'react';
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import { useDispatch } from "react-redux";
import { hideModal } from "../../data/modal.tsx";
import { InputTextField } from '../FormFields/RenderInputTextField.tsx';

import {
    Button,
    Divider,
    Grid,
    Typography,
    TextField,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';
import { CheckboxField } from '../FormFields/RenderCheckboxField.tsx';

const TenantForm = ({ tenant, isSuperAdmin }) => {    
    const dispatch = useDispatch();
    const location = useLocation();
    const [logo, setLogo] = useState(tenant && tenant.logo ? tenant.logo.contentUrl : undefined);

    const { handleSubmit, control, formState, setValue } = useForm({
        defaultValues: tenant,
    });

    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler = (data) => {          
        axios({
            method: tenant ? 'PUT' : 'POST',
            url: `${process.env.REACT_APP_API_URL}/tenants${ tenant && tenant['@id'] ? `/${tenant['@id'].replace('/api/tenants/', '')}` : '' }`,
            headers: {
              "Content-Type": "application/ld+json; charset=utf-8",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: data
        }).then((res) => {
            window.location.href = location.pathname
        }).catch((error) => console.log(error));
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let files = e.target.files;
    
        if (files) {
          const formData = new FormData();
    
          Array.from(files).forEach((file) => {
            formData.append("file", file);
          });
    
          try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/files`, {
              method: "POST",
              body: formData,
            });
    
            const data = await result.json();

            setLogo(URL.createObjectURL(files[0]))
            setValue('logo', data["@id"])
          } catch (error) {
            console.error(error);
          }
        }
      };
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12}>
                        <Typography variant='h6' className='form-fields-title'>
                            Tenant
                        </Typography>
                        <Grid item xs={12} className='form-fields-container'>
                            <Grid item xs={12} sm={5} sx={{ position: 'relative' }}>
                               { logo ? (
                                <img src={`${ logo }`} height={ 200 } alt="TenantLogo"/>
                               ) : ''}
                               <Button
                                variant="contained"
                                component="label"
                                sx={{ position: 'absolute', top: '2px', left: '2px' }}
                                >
                                Upload Logo
                                <TextField
                                    className="hidden"
                                    type="file"
                                    onChange={handleFileChange}
                                    InputProps={{ disableUnderline: true }}
                                />
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputTextField
                                    name='name'
                                    label='Name'
                                    control={ control }
                                    required
                                />
                            </Grid>
                            { isSuperAdmin ? (
                                <Grid item xs={12} sm={4}>
                                    <CheckboxField
                                    name="isActive"
                                    label="is Active"
                                    control={control}
                                    defaultValue={true}
                                    />
                                </Grid>
                            ): ''}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
                        <Divider orientation="horizontal" />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography variant='h6' className='form-fields-title'>
                            Address
                        </Typography>
                        <Grid item xs={12} className='form-fields-container'>
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.number'
                                    label='Number'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={4}>
                                <InputTextField
                                    name='address.street'
                                    label='Street'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.postalCode'
                                    label='Postal Code'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.city'
                                    label='City'
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.country'
                                    label='Country'
                                    control={ control }
                                />
                            </Grid> 
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='button-wrapper'>
                        <Grid item>
                            <Button 

                                onClick={() => {dispatch(hideModal())}}
                                disabled={ isSubmitting }
                                variant='contained'
                            >
                                <CloseIcon fontSize="inherit" />&nbsp;Close
                            </Button>
                        </Grid>
                        <Button 
                            type='submit'
                            disabled={ isSubmitting }
                            variant='contained'
                        >
                            <SaveIcon fontSize="inherit" />&nbsp;Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default TenantForm;