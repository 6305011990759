import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../data/modal.tsx";
import { DEFAULT_LANGUAGES, getProductDiscriptionByLanguages } from '../Helpers.tsx';
import RenderClassificationByProduct from './ClassificationByProduct.tsx';
import ProductSDS from './ProductSDS.tsx';

import {
    Button,
    Divider,
    Grid,
    Typography,
} from "@mui/material";

import {
  Close as CloseIcon,
} from "@mui/icons-material";

const ProductView = ({product, isModal=false}) => {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
      modal: state.modal
  }));

  return (
    <>
    <Grid container gap={2} justifyContent='flex-start' flexWrap='nowrap'>
        <Grid item sx={{ display:'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100px !important' }}>
          <Typography fontWeight={700} fontSize='22px'>
              {`UN ${product.unNr ?? ''}`}
          </Typography>
        </Grid>
        <Grid item>
          { getProductDiscriptionByLanguages(product, DEFAULT_LANGUAGES, 'inherit') }
        </Grid>
    </Grid>
    <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
        <Divider orientation="horizontal" />
    </Grid>
    <RenderClassificationByProduct classifications={product.classifications}/>
    <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
        <Divider orientation="horizontal" />
    </Grid>
    <ProductSDS product={ product } dispatch={dispatch}/>
    { selectedData.modal.modal && isModal ? (
      <>
       <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
          <Divider orientation="horizontal" />
       </Grid>
       <Grid item xs={12} className='button-wrapper'>
          <Grid item>
              <Button 
                  onClick={() => { dispatch(hideModal()) }}
                  variant='contained'
              >
                  <CloseIcon fontSize="inherit" />&nbsp;Close
              </Button>
          </Grid>
      </Grid>
      </>
    ) : ''}
   
    </>
  )
}

export default ProductView
