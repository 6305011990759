import React from 'react';
import { showChildModal } from "../../data/modal.tsx";

import {
    Grid,
    Typography,
} from "@mui/material";

import {
  FileOpenSharp as FileOpenSharpIcon,
} from "@mui/icons-material";

const ProductSDS = ({dispatch, product}) => {

  return (
    <>
    { product && product.sdsFile ? (
      <>
        <Grid 
          className='product-sds'
          container 
          gap={2} 
          flexWrap='nowrap' 
          flexDirection='row' 
          justifyContent='flex-start'
          alignItems='center'
          marginTop={1}
          onClick={() =>
            dispatch(
              showChildModal({
                title: `File View`,
                description: product.sdsFile.description ?? '',
                form: "fileView",
                formProps: {
                  file: product.sdsFile,
                  showCloseButton: true,
                },
              })
            )
          }
        >
          <Grid item sx={{ display:'flex', alignItems: 'center' }}>
            <FileOpenSharpIcon size='large' />
          </Grid>
          <Grid item paddingLeft={0}>
            <Typography fontSize='inherit' fontWeight={700}>
              SDS { `SDS ${product.nameEn ?? ''} ${product.nameNl ? `- ${product.nameNl}`: ''} ${product.nameFr ? `- ${product.nameFr}`: ''} ${product.nameDe ? `- ${product.nameDe}`: ''}` }
            </Typography>
          </Grid>
        </Grid>
      </>
    ) : ''}
    </>
  )
}

export default ProductSDS
